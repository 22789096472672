import React, { useState, useEffect} from 'react'
import NotificationAndMessages from './NotificationAndMessages'
import './Settings.css'
import axios from 'axios'
import { useAuth } from '../../Context/AuthContext'
import { toast } from 'react-toastify'
import Portfolio from './Profile/Portfolio'
//firebase
import { storage, auth } from '../../Firebase'
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { updateProfile } from "firebase/auth";
import Wallet from './Wallet'

export default function Settings() {

  let { currentUser } = useAuth()

  const [tab, setTab] = useState(1)
  const [img, setImg] = useState("")
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [shortBio, setShortBio] = useState("")
  const [fullBio, setFullBio] = useState("")
  const [skill, setSkill] = useState("")
  const [skills, setSkills] = useState([])
  const [languages, setLanguages] = useState([])
  const [language, setLanguage] = useState("")

  useEffect(() => {
    getUserInfo()
  },[])

  const getUserInfo = () => {
    axios.post(`${process.env.REACT_APP_API_URL}/freelance/get-user-info`, {_id: currentUser.uid})
    .then((res) => {
        let { firstName, lastName, shortBio, fullBio, skills, languages, img} = res.data
        setFirstName(firstName);
        setLastName(lastName);
        setShortBio(shortBio ? shortBio : "")
        setFullBio(fullBio ? fullBio : "")
        setSkills(skills)
        setLanguages(languages)
        setImg(img)

    })
    .catch((e) => {
        console.log(e)
    })
}

const addSkill = () => {
  if(!skill){
      toast.error("Please add a skill")
  } else {
      axios.post(`${process.env.REACT_APP_API_URL}/freelance/update-skills`, {_id: currentUser.uid, skills:[...skills, skill]})
      .then((res) => {
          setSkill("")
          getUserInfo()
      })
      .catch((e) => {
          console.log(e)
      })
  }
}

const removeSkill = (index) =>{
 let updatedSkills = skills.filter((_, i) => i !== index)

  axios.post(`${process.env.REACT_APP_API_URL}/freelance/update-skills`, {_id: currentUser.uid, skills: updatedSkills})
  .then((res) => {
      getUserInfo()
  })
  .catch((e) => {
      console.log(e)
  })
}

const addLanguage = () => {
  if(!language){
      toast.error("Please add a language")
  } else {
      
      axios.post(`${process.env.REACT_APP_API_URL}/freelance/update-languages`, {_id: currentUser.uid, languages:[...languages, language]})
      .then((res) => {
          setLanguage("")                
          getUserInfo()
      })
      .catch((e) => {
          console.log(e)
      })
  }
}

const removeLanguage = (index) =>{
  let updatedLanguages = languages.filter((_, i) => i !== index)
  axios.post(`${process.env.REACT_APP_API_URL}/freelance/update-languages`, {_id: currentUser.uid, languages: updatedLanguages})
  .then((res) => {
      getUserInfo()
  })
  .catch((e) => {
      console.log(e)
  })
}

const updatePrimaryInfo = () => {

  if(!firstName || !lastName ){
      toast.error("Please add full name")
  } else if((fullBio.length < 150 && fullBio.length > 0)) {
      toast.error("Full bio must be at least 150 characters or empty")
  } else if(shortBio.length > 70) {
    toast.error("Short bio must be no longer than 70 characters")
  } else {

      updateProfile(auth.currentUser, {
          displayName: `${firstName} ${lastName}`
        }).then(() => {
          axios.post(`${process.env.REACT_APP_API_URL}/freelance/update-primary-info`, {_id: currentUser.uid, firstName, lastName, shortBio, fullBio})
          .then((res) => {
              getUserInfo()
              toast.success("Updated successfully")
          })
          .catch((e) => {
              console.log(e)
          })
        }).catch((error) => {
          console.log(error)

        });
      
  }
 
}

//firebase storage

const imgRef = ref(storage, `freelancers/profile/${currentUser.uid}`);
const updateImg = (file) =>{
   uploadBytes(imgRef, file).then((snapshot) => {

       getDownloadURL(imgRef)
       .then((url) => {
          updateProfile(auth.currentUser, {
              photoURL: url
            }).then(() => {
              setImg(url)
              saveProfilePic(url)
            }).catch((error) => {
                console.log(error)
              toast.error("Error")
            });
           

       })
       .catch((error) => {
           console.log(error)
       });
     });
}

const saveProfilePic = (url) =>{
  axios.post(`${process.env.REACT_APP_API_URL}/freelance/update-profile-pic`, {_id: currentUser.uid, img: url})
  .then((res) => {
      console.log("Updated")
  })
  .catch((e) => {
      console.log(e)
  })
}


  return (
    <div className='business-outlet'>
         <div id='business-dash-content'>
            <p className='advidity-header'>Settings</p>
            <p className='advidity-subheader'>View and manage your personal and professional details</p>


          <div style={{width: '100%', minHeight: 300, display: 'flex', flexWrap: 'wrap'}}>
            <div className='tab-container' style={{width: 400}}>
                <div className={tab === 1 ? 'tab-active' : 'tab'} onClick={() => setTab(1)}>
                  <p>Profile</p>
                </div>

                <div className={tab === 2 ? 'tab-active' : 'tab'} onClick={() => setTab(2)}>
                  <p>Professional Info</p>
                </div>

                <div className={tab === 3 ? 'tab-active' : 'tab'} onClick={() => setTab(3)}>
                  <p>Wallet</p>
                </div>
            </div>
            {tab === 1 && 
          <>
            <div className='profile-container'>
              <label>Profile Picture</label>
              <div id='profile-avatar' style={img ? {backgroundImage: `url(${img})`} : {backgroundImage: `url('https://firebasestorage.googleapis.com/v0/b/zest-b90d0.appspot.com/o/avatar.png?alt=media&token=729becdf-6299-4cbd-9271-ba1442b8eaa9')`}}>
                      <input type="file" accept="image/*" id='img-input' onChange={(e)=>updateImg(e.target.files[0])}/>
              </div>
            </div>

            <div className='profile-container'>
              <label>First name</label>
              <input className='advidity-input' style={{width: 500, maxWidth: '60vw'}} value={firstName} onChange={(e) => setFirstName(e.target.value)}/>
            </div>

            <div className='profile-container'>
              <label>Last name</label>
              <input className='advidity-input' style={{width: 500, maxWidth: '60vw'}} value={lastName} onChange={(e) => setLastName(e.target.value)}/>
            </div>

            <div className='profile-container'>
              <label>Short Bio</label>
              <div>
                <textarea className='advidity-input' style={{width: 500, height: 60, resize:'none', maxWidth: '60vw'}} value={shortBio} onChange={(e) => setShortBio(e.target.value)}/>
                <p style={{fontSize: 12, color: 'grey'}}>{shortBio.length}/70</p>
              </div>
            </div>

            <div className='profile-container'>
              <label>Full Bio</label>
              <div>
                <textarea className='advidity-input' style={{width: 500, height: 100, resize:'none', maxWidth: '60vw'}} value={fullBio} onChange={(e) => setFullBio(e.target.value)}/>
                <p style={{fontSize: 12, color: 'grey'}}>min. 150 characters</p>
                <p style={{fontSize: 12, color: 'grey'}}>{fullBio.length}/150</p>

              </div>
            </div>

            <button className='primary-btn' onClick={updatePrimaryInfo}>Update</button>

            </>
}
          {tab === 2 && 
          <>
          <div className='profile-container'>

              <label>Skills</label>
              <input className='advidity-input' placeholder='eg. Graphic Design' style={{width: 500, maxWidth: '60vw'}} value={skill} onChange={(e) => setSkill(e.target.value)}/>
              <div className='add-icon' onClick={addSkill}>
                  <p style={{color:'white'}}>+</p>
              </div>
              <div style={{display:'flex', flexWrap:'wrap', width: '100%'}}>

                {skills?.length < 1 ?
                <p style={{margin:'2rem'}}>No skills added</p>

                :
                skills?.map((skill, i) =>{
                    return (
                    <div key={i} className='profile-chip'>

                        <p>{skill}</p>

                        <p className='remove-cross'  onClick={() => removeSkill(i)}>x</p>

                    </div>
                    )
                })

                }

              </div>
            </div>


            <div className='profile-container'>

                <label>Languages</label>
                <input className='advidity-input' placeholder='eg. French' style={{width: 500, maxWidth: '60vw'}} value={language} onChange={(e) => setLanguage(e.target.value)}/>
                <div className='add-icon' onClick={addLanguage}>
                    <p style={{color:'white'}}>+</p>
                </div>
                <div style={{display:'flex', flexWrap:'wrap', width: '100%'}}>

                  {languages?.length < 1 ?
                  <p style={{margin:'2rem'}}>No languages added</p>

                  :
                  languages?.map((language, i) =>{
                      return (
                      <div key={i} className='profile-chip'>

                          <p>{language}</p>

                          <p className='remove-cross'  onClick={() => removeLanguage(i)}>x</p>

                      </div>
                      )
                  })

                  }

                </div>
            </div>
          </>
          }

          {tab === 3 && 
          <Wallet />
          }
            
          </div>

        </div>

        <NotificationAndMessages />
        

    </div>
  )
}
