import React, { useState} from 'react'
import moment from 'moment'

export default function JobsList({jobs, setViewJob, setJobId}) {


return (
 <div>
    <p className='advidity-subheader' style={{margin: '1rem', fontSize: 16}}>Showing {jobs.length} Jobs</p>

    <div id='jobs-scroll-container'>

    {jobs.length < 1 ?  
    <p>No jobs added</p>    
    : 
    jobs.map((job, i) => {
      return (
        <div className='list-item' key={i} onClick={() => { setViewJob(true); setJobId(job._id)}}>
          <div style={{ display:'flex', flexWrap:'wrap', justContents: 'space-between', alignItems: 'center'}}>
            <img className='list-overview-img' src={job.img} alt={job.businessName}/>
            <div style={{width: '70%', padding: '0 2rem'}}>
                <p style={{fontFamily: 'Satoshi', fontSize: 16, color: '#1e1e1e', letterSpacing: -1}}>{job.title}</p>
                <div style={{display: 'flex', flexDirection:'row', alignItems :'center'}}>
                  <p style={{fontFamily: 'Inter', fontSize: 14, color: '#75788D', letterSpacing: -1}}>{job.businessName}</p>
                  <div style={{height: 6, width: 6, backgroundColor: '#1e1e1e', borderRadius: 3, margin: '0 1rem'}}/>
                  <div className='job-chip'>
                    <p>{job.primarySkill}</p>
                  </div>
                  <div className='job-chip'>
                    <p>{job.secondarySkill}</p>
                  </div>
                  <div className='job-chip'>
                    <p>£{job.budget}</p>
                  </div>
                </div>
            </div>

            <div>
              <p style={{fontFamily: 'Satoshi', fontSize: 14, color: '#1e1e1e', letterSpacing: -1}}>Birmingham, UK</p>
              <p style={{fontFamily: 'Inter', fontSize: 12, color: '#75788D', letterSpacing: -1}}>Posted {moment(job.createdAt).fromNow()} ago</p>

            </div>
          </div>
          <p style={{fontFamily: 'Inter', color: '#75788D', fontSize: 14, letterSpacing: -0.5, padding: '1rem'}}>
            {job.description}
          </p>
        </div>
      )
    })

    }
    </div>

 </div>
  )
}
