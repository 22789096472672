import React, { useState, useEffect} from 'react'
import './Jobs.css'
import NotificationAndMessages from './NotificationAndMessages'
import axios from 'axios'
import moment from 'moment'
import icon from '../../Assets/icons/more-info.png'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../Context/AuthContext'
import medal from '../../Assets/contest-img.png'

export default function Contests() {

  let { currentUser } = useAuth()
  const [contests, setContests] = useState([])
  const [applied, setApplied] = useState([])
  const [tab, setTab] = useState(1)

  let navigate = useNavigate()

  //Retrive all active jobs from all companies
  const getAllContests = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/contests/get-all-active-contests`)
    .then((res) => {
      setContests(res.data)
    })
    .catch((e) => {
      console.log(e)
    })
  }

   //Retrive all applied for jobs 
   const getAppliedFor = () => {
    axios.post(`${process.env.REACT_APP_API_URL}/contests/get-applied-for-by-id`, {applicantId: currentUser.uid})
    .then((res) => {
      setApplied(res.data)
    })
    .catch((e) => {
      console.log(e)
    })
  }

  useEffect(() => {
    getAllContests()
    getAppliedFor()
  }, [])
  return (
    <div className='business-outlet'>
      <div id='business-dash-content'>
            <p className='advidity-header'>One-off Contests</p>
            <p className='advidity-subheader'>Find your preferred jobs, contests, and many more</p>

            <div className='create-contest-box'>
              <p style={{fontFamily:'Satoshi', fontSize: 24, letterSpacing: -0.5, color: '#fff'}}>Compete with other freelancers and win points and cash prizes.</p>
              <p style={{fontFamily:'Inter', fontSize: 16, letterSpacing: -0.5, color: '#fff', margin: '1rem 0', maxWidth: 600, lineHeight: 1.6}}>An opportunity to stand out amongst the rest by participating in different contests</p>
              <img style={{height: "70%", position: 'absolute', right: '5%', bottom: '15%'}} src={medal} alt='"Medal Image'/>

          </div>

          <div style={{width: '100%', minHeight: 300, display: 'flex', flexWrap: 'wrap'}}>
            <div className='tab-container' style={{width: 400}}>

              <div className={tab === 1 ? 'tab-active' : 'tab'} onClick={() => setTab(1)}>
                <p>All Contests</p>
                <div className='tab-square'>
                  <p>{contests.length}</p>
                </div>
              </div>

              <div className={tab === 2 ? 'tab-active' : 'tab'}onClick={() => setTab(2)}>
                <p>Applied</p>
                <div className='tab-square'>
                  <p>{applied.length}</p>
                </div>
              </div>


            </div>
          {tab ===1 &&
            <table className='job-table'>
            <tr>
              <th>Deadline</th>
              <th>Project Title</th>
              <th>Skill Required</th>
              <th>Cash Prize</th>
              <th></th>
            </tr>
          
            {contests.length < 1 ?
            <tr>
              <td colSpan={5}>No jobs added</td>
            </tr>
            : 
            contests.map((contest, i) => {
              return (
                <tr key={i}>
                  <td>{moment(contest.deadline).format('Do MMM YYYY')}</td>
                  <td>{contest.title}</td>
                  <td>{contest.primarySkill}</td>
                  <td>£{contest.prizeMoney}</td>
                  <td className='more-info-cell' style={{display: 'flex', alignItems: 'center', flexWrap:'wrap'}} onClick={() => navigate(`/freelance/contests/${contest._id}`)}>
                    <p>More details</p>
                    <img style={{height: 15, width: 15, marginLeft: 10}} src={icon} alt='More info icon'/>
                  </td>


                </tr>
              )
            })
            }
            </table>
          }

          {tab === 2 &&
            <table className='job-table'>
            <tr>
              <th>Contest Title</th>
              <th>Stage</th>
              <th>Applied</th>
              <th></th>
            </tr>
          
            {applied.length < 1 ?
            <tr>
              <td colSpan={5}>No contests applied for</td>
            </tr>
            : 
            applied.map((contest, i) => {
              return (
                <tr key={i}>
                  <td>{contest.contestTitle}</td>
                  <td>{contest.stage}</td>
                  <td>{moment(contest.createdAt).format('Do MMM YYYY')}</td>
                  <td className='more-info-cell' style={{display: 'flex', alignItems: 'center', flexWrap:'wrap'}} onClick={() => navigate(`/freelance/contests/${contest.contestId}`)}>
                    <p>More details</p>
                    <img style={{height: 15, width: 15, marginLeft: 10}} src={icon} alt='More info icon'/>
                  </td>


                </tr>
              )
            })
            }
            </table>
          }
          </div>

        </div>

        <NotificationAndMessages />
        

      
    </div>
  )
}
