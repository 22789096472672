import React, { useState, useEffect} from 'react'
import { Link, Outlet, useLocation } from 'react-router-dom'
import './BusinessUI.css'
import { useAuth } from '../../Context/AuthContext'
import { toast } from 'react-toastify'
import Welcome from '../Welcome';
import advidity from '../../Assets/logo.png'
import axios from 'axios'
//Firebase
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import {  signOut, sendEmailVerification, updateProfile } from "firebase/auth";
import { auth, storage } from '../../Firebase';

export default function BusinessUI() {

  let { currentUser, newUser, setNewUser } = useAuth()
  let location = useLocation();

  const [active, setActive] = useState('')
  const [logo, setLogo] = useState('')

  useEffect(() => {
    axios.post(`${process.env.REACT_APP_API_URL}/business/get-account-info`, {_id: currentUser.uid})
    .then((res) => {
      setLogo(res.data.logo)

    })
    .catch((e) => {
      console.log(e)
    })
  }, [])

  useEffect(() => {
    let url = location.pathname

    if(url === '/business'){
      setActive("Dashboard")
    } else if(url.startsWith('/business/jobs')){
      setActive("Jobs")
    } else if(url.startsWith('/business/contests')){
      setActive("Contests")
    } else if(url.startsWith('/business/applicants')){
      setActive("Applicants")
    } else if(url.startsWith('/business/wallet')){
      setActive("Wallet")
    } else if(url.startsWith('/business/messages')){
      setActive("Messages")
    } else {
      setActive("")
    }

  }, [location])


    const handleLogout = () =>{
        signOut(auth).then(() => {
          // Sign-out successful.
        }).catch((error) => {
          // An error happened.
        });
      }

      const sendVerification = () => {
        sendEmailVerification(auth.currentUser)
        .then(() => {
          toast.success("Email verification sent!")
        });
      }

      const imgRef = ref(storage, `businesses/logos/${currentUser.uid}`);
      const updateImg = (file) =>{
       if(file?.size > 2097152){
         toast.error("Image must be less than 2mb")
       } else if( !file){
         return
       } else {
         uploadBytes(imgRef, file).then((snapshot) => {
   
           getDownloadURL(imgRef)
           .then((url) => {
             updateProfile(auth.currentUser, {
                 photoURL: url
               }).then(() => {
                 setLogo(url)
                 saveProfilePic(url)
               }).catch((error) => {
                   console.log(error)
                 toast.error("Error")
               });
               
 
           })
           .catch((error) => {
               console.log(error)
           });
         });
       }
          
      }
 
     const saveProfilePic = (url) =>{
         axios.post(`${process.env.REACT_APP_API_URL}/business/update-profile-pic`, {_id: currentUser.uid, logo: url})
         .then((res) => {
             console.log("Updated")
         })
         .catch((e) => {
             console.log(e)
         })
     }

  return (
    <div>
        <header id='business-nav'>
          <div>
            <img src={advidity} style={{width: 150, marginBottom: 50}}/>

            <nav>
              <p style={{fontFamily: 'Inter', fontSize: 11, color:'#111111', opacity: 0.5}}>MENU</p>
                <Link to={'/business'} className={active === 'Dashboard' ? 'business-nav-item-active' : 'business-nav-item'} >
                  <img className={active === 'Dashboard' ? 'nav-icon icon-filter' : 'nav-icon'} src={require('../../Assets/icons/home.png')} alt='Home icon'/>
                  <p className={active === 'Dashboard' ? 'business-link-txt-active' : 'business-link-txt'}>Dashboard</p>
                </Link>
                <Link to={'jobs'} className={active === 'Jobs' ? 'business-nav-item-active' : 'business-nav-item'} >
                  <img className={active === 'Jobs' ? 'nav-icon icon-filter' : 'nav-icon'} src={require('../../Assets/icons/jobs.png')} alt='Jobs icon'/>
                  <p className={active === 'Jobs' ? 'business-link-txt-active' : 'business-link-txt'}>Jobs</p>
                </Link>
                <Link to={'contests'} className={active === 'Contests' ? 'business-nav-item-active' : 'business-nav-item'} >
                  <img className={active === 'Contests' ? 'nav-icon icon-filter' : 'nav-icon'} src={require('../../Assets/icons/contests.png')} alt='Contests icon'/>
                  <p className={active === 'Contests' ? 'business-link-txt-active' : 'business-link-txt'}>One-off Contests</p>
                </Link>
                <Link to={'applicants'} className={active === 'Applicants' ? 'business-nav-item-active' : 'business-nav-item'}>
                  <img className={active === 'Applicants' ? 'nav-icon icon-filter' : 'nav-icon'} src={require('../../Assets/icons/applicants.png')} alt='Applicants icon'/>
                  <p className={active === 'Applicants' ? 'business-link-txt-active' : 'business-link-txt'}>Applicants</p>
                </Link>
                {/*<Link to={'wallet'} className={active === 'Wallet' ? 'business-nav-item-active' : 'business-nav-item'} >
                  <img className={active === 'Wallet' ? 'nav-icon icon-filter' : 'nav-icon'} src={require('../../Assets/icons/wallet.png')} alt='Wallet icon'/>
                  <p className={active === 'Wallet' ? 'business-link-txt-active' : 'business-link-txt'}>Wallet</p>
  </Link>*/}
                <Link  to={'messages'} className={active === 'Messages' ? 'business-nav-item-active' : 'business-nav-item'} >
                  <img className={active === 'Messages' ? 'nav-icon icon-filter' : 'nav-icon'} src={require('../../Assets/icons/messages.png')} alt='Messages icon'/>
                  <p className={active === 'Messages' ? 'business-link-txt-active' : 'business-link-txt'}>Messages</p>
                </Link>

                <button className='business-nav-item' id='business-logout-btn' onClick={handleLogout}>
                  <img className='nav-icon' src={require('../../Assets/icons/logout.png')} alt='Logout icon'/>
                  <p className='business-link-txt'>Logout</p>
                </button>

            </nav>
            </div>

            <div className='advidity-row' >
              {logo ? 
              <>
                <input type="file" accept="image/*"  name="pic1" id="pic1" style={{display:'none'}} onChange={(e)=>updateImg(e.target.files[0])}/>
                <label htmlFor="pic1">
                  <img id='business-avatar' src={logo} alt={currentUser?.displayName}/>
                </label>
              </>
              
              :
              <>
              <input type="file" accept="image/*"  name="pic1" id="pic1" style={{display:'none'}} onChange={(e)=>updateImg(e.target.files[0])}/>
              <label htmlFor="pic1">
                <div id='business-avatar' style={{display:'flex', justifyContent:'center', alignItems: 'center', flexWrap:'wrap'}}>
                  <p style={{fontFamily: 'Inter', fontSize: 12, textAlign:'center'}}>Your Logo</p>
                </div>
              </label>
            </>
             
              }

              <p style={{marginLeft: 15, fontFamily: 'Inter', fontWeight: 600}}>{currentUser?.displayName}</p>
            </div>

        </header>

        {/*newUser ?
        
          <Welcome setNewUser={setNewUser}/>
 
  : */}
        
        {!currentUser?.emailVerified && 
       <div id='blur-overlay'>

          <div className='blur-info-container'>

            <div style={{width: '100%', height: 300, backgroundColor: '#f1f1f1', borderRadius: 12, marginBottom: 20}}>

            </div>

            <h2 className='advidity-header' style={{fontSize: 32}}>We just sent you a verification email</h2>

            <p className='advidity-subheader' style={{fontSize: 18}}>Hi there, we just sent you a secure verification link. Kindly check your inbox and spam. </p>

            <button className='primary-btn' style={{fontSize: 18, width: 150}} onClick={sendVerification}>Resend link</button>

          </div>

        </div>
}

        <Outlet context={{ logo }}/>
    </div>
  )
}
