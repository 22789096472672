import React, { useState, useEffect} from 'react'
import arrow from '../../../Assets/icons/chevron.png'
import arrowLeft from '../../../Assets/icons/chevron-left.png'
import moment from 'moment'
import { useAuth} from '../../../Context/AuthContext'
import axios from 'axios'
import { skillList } from '../../../Utilities'
import { useOutletContext } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'

export default function CreateContest({setOpenContest, setTab, getMyContests}) {

  const { logo } = useOutletContext();


  const [step, setStep] = useState(1)
  const [saving, setSaving] = useState(false)
  const [primarySkills, setPrimarySkills] = useState([])
  const [secondarySkills, setSecondarySkills] = useState([])

  let { currentUser} = useAuth()

  //form state pg 1
  const [title, setTitle] = useState("")
  const [brandName, setBrandName] = useState("")
  const [primarySkill, setPrimarySkill] = useState("")
  const [secondarySkill, setSecondarySkill] = useState("")  
  const [maxApplicants, setMaxApplicants] = useState("")
  const [prizeMoney, setPrizeMoney] = useState("")
  const [number, setNumber] = useState("")
  const [unit, setUnit] = useState("")
  //pg 2
  const [description, setDescription] = useState("")
  const [deliverables, setDeliverables] = useState("")
  const [notes, setNotes] = useState("")

  useEffect(() => {
    setPrimarySkills(Object.keys(skillList))
  }, [])

  useEffect(() => {
    if(primarySkill){
      setSecondarySkill("")
      setSecondarySkills(skillList[primarySkill])
    } else {
      return
    }
  }, [primarySkill])

  const handleSubmit = () => {
    let payload = {
      businessId: currentUser.uid,
      businessName: currentUser.displayName,
      img: logo,
      title, 
      brandName,
      primarySkill,
      secondarySkill,
      maxApplicants,
      prizeMoney,
      deadline: moment().add(number, unit).format(),
      submissionDate: moment().format(),
      description,
      deliverables,
      notes
    }
    if(!logo) {
      toast.error("Please upload your company logo")
    } else if( !title || !primarySkill || !secondarySkill || !prizeMoney || (!number && !unit) || !description || !deliverables || !maxApplicants || !brandName) {
      toast.error("Please add all necessary fields")
    } else {
      setSaving(true)
      axios.post(`${process.env.REACT_APP_API_URL}/contests/create-contest`, payload)
      .then(() => {
        getMyContests()
        setStep(4)
        setSaving(false)

      })
      .catch((e) => {
        console.log(e)
        setSaving(false)

      })
    }

  }

  return (
    <div id='create-contest'>
        <div id='create-contest-container'>
            <button onClick={() => setOpenContest(false)} id='close-contest-btn'>x</button>

            

            {step === 1 &&
            <>
            <div className='create-contest-header'>
                <p className='advidity-header' style={{fontSize: 28}}>Create your one-off contest</p>

                <p className='advidity-subheader' style={{fontSize: 16, margin: '10px 0'}}>Please fill the form with necessary information needed for the contest, both its requirements and deliverables.</p>
            </div>
              <div className='new-contest-container'>
                <p>1/2</p>

                <p className='advidity-header' style={{fontSize: 20, margin: '30px 0 10px 0'}}>Contest Title</p>
                <input className='contest-input' type='text' placeholder='eg. Business Card Design' value={title} onChange={(e) => setTitle(e.target.value)}/>

                <p className='advidity-header' style={{fontSize: 20, margin: '30px 0 10px 0'}}>Brand Name</p>
                <input className='contest-input' type='text' placeholder='Please provide company name' value={brandName} onChange={(e) => setBrandName(e.target.value)}/>

                <p className='advidity-header' style={{fontSize: 20, margin: '30px 0 10px 0'}}>Required Skill</p>
                <select className='contest-input' value={primarySkill} onChange={(e) => setPrimarySkill(e.target.value)}>
                  <option value="" disabled>Choose Skill</option>
                  {primarySkills.map((skill, i) => {
                    return (
                      <option key={i} value={skill}>{skill}</option>

                    )
                  })}
            
                </select>

                <p className='advidity-header' style={{fontSize: 20, margin: '30px 0 10px 0'}}>Sub Category</p>
                <select className='contest-input' value={secondarySkill} onChange={(e) => setSecondarySkill(e.target.value)}>
                  <option value="" disabled>Choose Skill</option>
                  {secondarySkills.map((skill, i) => {
                    return (
                      <option key={i} value={skill}>{skill}</option>

                    )
                  })}
            
                </select>

                <p className='advidity-header' style={{fontSize: 20, margin: '30px 0 10px 0'}}>Number of applications</p>
                <input className='contest-input' type='text' placeholder='eg. 20' value={maxApplicants} onChange={(e) => setMaxApplicants(e.target.value)}/>

                <p className='advidity-header' style={{fontSize: 20, margin: '30px 0 10px 0'}}>Prize Money</p>
                <input className='contest-input' type='number' value={prizeMoney} onChange={(e) => setPrizeMoney(e.target.value)}/>

                <p className='advidity-header' style={{fontSize: 20, margin: '30px 0 10px 0'}}>Contest Duration</p>
                <div style={{display:'flex', justifyContent:'space-between'}}>
                  <input className='contest-input' style={{width: '45%'}} type='number' placeholder='Please enter number' value={number} onChange={(e) => setNumber(e.target.value)}/>
                  <select className='contest-input' style={{width: '45%'}} value={unit} onChange={(e) => setUnit(e.target.value)}>
                    <option value="" disabled>Select</option>
                    <option value={'days'}>days</option>
                    <option value={'weeks'}>weeks</option>
                  </select>

                </div>
              </div>
              </>
            } 

          {step === 2 &&
            <>
            <div className='create-contest-header'>
              <p className='advidity-header' style={{fontSize: 28}}>What is your contest for?</p>

              <p className='advidity-subheader' style={{fontSize: 16, margin: '10px 0'}}>Please fill the form with necessary information needed for the contest, both its requirements and deliverables.</p>

            </div>
              <div className='new-contest-container'>
                <p>2/2</p>

                <p className='advidity-header' style={{fontSize: 20, margin: '30px 0 10px 0'}}>Contest Description</p>
                <textarea className='contest-textarea' 
                placeholder='Provide answers to this question; Why do you need this piece of work? What are you looking to achieve?' 
                value={description} onChange={(e) => setDescription(e.target.value)}
                />

                <p className='advidity-header' style={{fontSize: 20, margin: '30px 0 10px 0'}}>Please list the deliverables/Submission requirements</p>
                <textarea className='contest-textarea' 
                placeholder='It should be written in this format.&#10;1. Deliverable one&#10;2. Deliverable two and so on' 
                value={deliverables} onChange={(e) => setDeliverables(e.target.value)}
                />

                <p className='advidity-header' style={{fontSize: 20, margin: '30px 0 10px 0'}}>Additional Notes (Optional)</p>
                <textarea className='contest-textarea' 
                placeholder='Any extra notes you feel the applicants should know.' 
                value={notes} onChange={(e) => setNotes(e.target.value)}
                />

              </div>
              </>
            } 

          {step === 3 &&
            <>
            <div className='create-contest-header' style={{display: 'flex', alignItems:'center'}}>
                <img id='business-avatar' style={{marginRight: 20}} src={logo} alt={`${currentUser.displayName} logo`}/>

                <div>
                  <p className='advidity-subheader' style={{fontSize: 16, margin: 0}}>{title}</p>
                  <p className='advidity-header' style={{fontSize: 22, }}>{primarySkill}</p>
                </div>
                
             </div>
              <div className='new-contest-container' style={{padding: '3rem'}}>
                <p>Overview</p>

                <p className='advidity-subheader' style={{fontSize: 14, margin: '40px 0 10px 0'}}>Maximum number of applicants</p>
                <p className='advidity-header' style={{fontSize: 20, margin: 0}}>{maxApplicants}</p>

                <p className='advidity-subheader' style={{fontSize: 14, margin: '40px 0 10px 0'}}>Prize money</p>
                <p className='advidity-header' style={{fontSize: 20, margin: 0}}>£{prizeMoney}</p>

                <p className='advidity-subheader' style={{fontSize: 14, margin: '40px 0 10px 0'}}>Application deadline</p>
                <p className='advidity-header' style={{fontSize: 20, margin: 0}}>{moment().add(number, unit).format(' Do MMMM YYYY')}</p>


                <p className='advidity-subheader' style={{fontSize: 14, margin: '40px 0 10px 0'}}>Submission date</p>
                <p className='advidity-header' style={{fontSize: 20, margin: 0}}>{moment().format(' Do MMMM YYYY')}</p>

                <div style={{borderTop: '1px solid #f1f1f1', borderBottom: '1px solid #f1f1f1', marginTop: 40}}>
                  <p className='advidity-header' style={{fontSize: 20, margin: '40px 0 10px 0'}}>Contest task</p>
                  <p className='advidity-subheader' style={{fontSize: 14, margin: '20px 0 40px 0'}}>{description}</p>
                </div>

                <div style={{ borderBottom: '1px solid #f1f1f1', marginTop: 40}}>
                  <p className='advidity-header' style={{fontSize: 20, margin: '40px 0 10px 0'}}>Deliverables/Requirements for submission</p>
                  <textarea className='advidity-subheader' style={{fontSize: 14, margin: '20px 0 40px 0', resize: 'none', border: 'unset', width: '100%', height: 100}}>{deliverables}</textarea>
                </div>

                <div style={{ borderBottom: '1px solid #f1f1f1', marginTop: 40}}>
                  <p className='advidity-header' style={{fontSize: 20, margin: '40px 0 10px 0'}}>Additional notes</p>
                  <p className='advidity-subheader' style={{fontSize: 14, margin: '20px 0 40px 0'}}>{!notes ? "No notes added" : notes}</p>
                </div>

                <p style={{fontSize: 14}} className='advidity-subheader'>Please note, by submitting this content you are agreeing to our <a href='https://sandbox.advidity.app/business-contest-terms' target='_blank' style={{color:'#EB9486', fontSize: 16}}>Terms & Conditions</a></p>

              </div>
              </>
            } 

            {step === 4 &&
            <div>
                <p className='advidity-header' style={{fontSize: 28, margin: '2rem'}}>Congratulations, you have successfully created a new contest</p>
                <p className='advidity-subheader' style={{fontSize: 18, margin: '0 2rem'}}>You can proceed to your contest page</p>

                <button className='primary-btn' style={{margin: '2rem'}} onClick={() => {setTab(2); setOpenContest(false)}}>Proceed to my contests</button>

            </div>
            }

            {(step !== 1 && step !== 4 )   &&
            <button id='contest-back-btn' onClick={() => setStep((prev) => prev - 1)}><img style={{height: 15, filter: 'invert(1)'}} src={arrowLeft} alt="Next button"/></button>}

            {(step !== 3 && step !== 4 )  ? 
            <button id='contest-next-btn' onClick={() => setStep((prev) => prev + 1)}><img style={{height: 15, filter: 'invert(1)'}} src={arrow} alt="Next button"/></button>
            :
            step !== 4 &&
            <button className='primary-btn' id='submit-contest-btn' onClick={handleSubmit} disabled={saving}>{saving ? "Saving..." : "Create Contest"}</button>
          }
        </div>
    </div>
  )
}
