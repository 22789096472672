export const skillList = {
    "Graphic Design" : [
        "Logo Design", "Branding", "Marketing Collateral", "Infographics"
    ],
    "Web Development" : [
        "Front-end Development", "Back-end Development", "Full-stack Development", "Website Redesign"
    ],
    "Content Creation" : [
        "Copywriting", "Blog Writing", "Video Scripting", "Social Media Content"
    ],
    "Digital Marketing" : [
        "Social Media Marketing", "SEO Strategy", "Email Marlketing Campaigns", "Paid Advertising"
    ],
    "Photography & Videography" : [
        "Product Photography", "Video Editing", "Motion Graphics"
    ],
    "UI/UX Design" : [
        "User Interface Design", "User Experience Improvement", "Prototyping"
    ],
    "3D Modelling and Animation" : [
        "Architectural Rendering", "Product Animation", "3D Character Design"
    ],
    "Illustration" : [
        "Digital Illustration", "Vector Art", "Storyboard Illustration"
    ],
    "App Development" : [
        "Mobile App Development", "App UI/UX Design", "App Testing and Debugging"
    ],
    "Marketing Strategy" : [
        "Market Research", "Brand Strategy", "Campaign Planning"
    ],
    "Virtual Assistance" : [
        "Administrative Support", "Data Entry", "Customer Support"
    ],
    "eLearning Development" : [
        "Course Design", "Instructional Design", "eLearning Content Creation"
    ]
}