import React, { useState } from 'react';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import axios from 'axios';

const PaymentForm = ({ userId, name, email, amount, connectedAccountId, setPaymentSuccess }) => {
  const [error, setError] = useState(null);
  const [paying, setPaying] = useState(false);

  let stripe = useStripe()
  let elements = useElements()

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError(null)
    
    console.log(stripe, elements)

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement('card');

    try {
      const { error } = await stripe.createToken(cardElement);

      if (error) {
        console.error(error);
        setError(error.message || 'An error occurred.');
      } else {
        setPaying(true)

        const response = await axios.post(`${process.env.REACT_APP_API_URL}/payments/create-payment-intent`, {
            userId,
            name,
            email,
            amount,
            connectedAccountId,
        });

        // Assuming your server returns a clientSecret
        const { clientSecret } = response.data;

        const result = await stripe.confirmCardPayment(clientSecret, {
          payment_method: {
            card: cardElement,
          },
        });

        if (result.error) {
          console.error(result.error);
          setError(result.error.message || 'Payment failed.');
          setPaying(false)

        } else if (result.paymentIntent.status === 'succeeded') {
          // Payment was successful
          setPaymentSuccess(true);
          setPaying(false)

        }
      }
    } catch (error) {
      console.error('Error:', error.message);
      setError('An error occurred.');
    }
  };

  const cardStyle = {
    base:{
      fontSize: '16px'
    }
  }

  return (
      <form onSubmit={(e) => handleSubmit(e)} style={{margin: '10px 0'}}>
        <label className='advidity-subheader' style={{fontSize: 14}}>
          Card details
        </label>

        <div style={{padding: '20px 0'}}>
          <CardElement options={{style:cardStyle, hidePostalCode: true, disableLink: true}} />
        </div>


        {error && <p style={{ color: 'crimson' }}>{error}</p>}

        <button type='submit' className='primary-btn' disabled={paying}>{paying ? "Paying..." : "Pay Now"}</button>
      </form>
  );
};

export default PaymentForm;