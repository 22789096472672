import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../../Context/AuthContext'

export default function ContestList({contests, setViewContest, setContestId}) {

  let { currentUser } = useAuth()

  let navigate = useNavigate()
return (
 <div>
    <p className='advidity-subheader' style={{margin: '1rem', fontSize: 16}}>Showing {contests.length} Contests</p>

    <div id='jobs-scroll-container'>

    {contests.length < 1 ?  
    <p>No contests added</p>    
    : 
    contests.map((contest, i) => {
      return (
        <div className='list-item' key={i} onClick={() => { setViewContest(true); setContestId(contest._id)}}>
          <div style={{ display:'flex', flexWrap:'wrap', justContents: 'space-between', alignItems: 'center'}}>
            <img className='list-overview-img' src={contest.img} alt={`${contest.brandName} logo`}/>
            <div style={{width: '70%', padding: '0 2rem'}}>
                <p style={{fontFamily: 'Satoshi', fontSize: 16, color: '#1e1e1e', letterSpacing: -1}}>{contest.title}</p>
                <div style={{display: 'flex', flexDirection:'row', alignItems :'center'}}>
                  <p style={{fontFamily: 'Inter', fontSize: 14, color: '#75788D', letterSpacing: -1}}>{contest.brandName}</p>
                  <div style={{height: 6, width: 6, backgroundColor: '#1e1e1e', borderRadius: 3, margin: '0 1rem'}}/>
                  <div className='job-chip'>
                    <p>{contest.primarySkill}</p>
                  </div>
                  <div className='job-chip'>
                  <p>{contest.secondarySkill}</p>
                  </div>
                  <div className='job-chip'>
                    <p>£{contest.prizeMoney}</p>
                  </div>
                </div>
            </div>

            <div>
              {contest.businessId === currentUser.uid &&
                <button className='primary-btn' style={{height: 38, fontSize: 16}} onClick={() => navigate(`${contest._id}`)}>
                  <p>View Applicants</p>
                </button>
              }
              
            </div>
          </div>
          <p style={{fontFamily: 'Inter', color: '#75788D', fontSize: 14, letterSpacing: -0.5, padding: '1rem'}}>
            {contest.description}
          </p>
        </div>
      )
    })

    }
    </div>

 </div>
  )
}
