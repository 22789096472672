import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import './Auth.css'
import {  signInWithEmailAndPassword , sendPasswordResetEmail} from "firebase/auth";
import { auth } from '../../Firebase';
import { toast } from 'react-toastify'
import { useAuth } from '../../Context/AuthContext';
import eyeOpen from '../../Assets/icons/show.png'
import eyeClosed from '../../Assets/icons/hide.png'
import CreateAccount from './CreateAccount'
import tick from '../../Assets/icons/check.png'
import chevron from '../../Assets/icons/chevron.png'

export default function Login() {

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [tab, setTab] = useState("Log In")
    const [showPassword, setShowPassword] = useState(false)

    //Create account state
    const [accountType, setAccountType] = useState("")
    const [step, setStep] = useState(1)


    let { role, currentUser } = useAuth()
  
    let navigate = useNavigate()

    useEffect(() => {
      if(currentUser && role === "Business"){
        navigate('/business')
      } else if(currentUser && role === "Freelancer") {
        navigate('/freelance')
      } else {
        return
      }
    }, [role, currentUser])

    const login = () =>{
      if(!email || !password){
        toast.error("Please input all fields")
      } else {
        signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          // Signed in 
          const user = userCredential.user;
          console.log(userCredential, role)
        
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          toast.error(errorMessage)
        });
      }
      
    }
  
    const resetPassword = () =>{
      if(!email){
        toast.error("Please input email")
      } else {
        sendPasswordResetEmail(auth, email)
        .then(() => {
          toast.success("Password reset email sent!")
         
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          toast.error(errorMessage)
        });
      }
   
    }
  

  return (
    <div id='auth-page'>
        <div id='auth-left-container'>
            <h1 style={{color: '#C47B70'}}>Advidity</h1>

            {tab === 'Sign Up' &&
              <div id='sign-up-steps'>
                <p style={{borderBottom: '1px solid #f1f1f1', padding: '1rem 0', fontFamily: 'Inter', fontSize: 14, color: 'grey'}}>COMPLETE THESE STEPS</p>

                  <div className='sign-up-step'>
                      <div className='check-circle'>
                          {accountType && <img style={{height: 20}} src={tick} alt='tick icon'/>}
                      </div>

                      <div style={{marginLeft: 10, width:'80%'}}>
                        <p style={{color: '#272838', fontSize: 16, fontFamily: 'Satoshi'}}>Choose a profile</p>
                        <p style={{color: '#272838', fontSize: 12, fontFamily: 'Inter'}}>Choose between a business or freelance profile</p>
                      </div>

                      <img style={{height: 15}} src={chevron} alt='chevron icon'/>
                  </div>
                  <div className='sign-up-step'>
                      <div className='check-circle'>
                      </div>

                      <div style={{marginLeft: 10, width:'80%'}}>
                        <p style={{color: '#272838', fontSize: 16, fontFamily: 'Satoshi'}}>Provide personal details</p>
                        <p style={{color: '#272838', fontSize: 12, fontFamily: 'Inter'}}>Kindly provide the answers to the input fields</p>
                      </div>

                      <img style={{height: 15}} src={chevron} alt='chevron icon'/>
                  </div>
                  <div className='sign-up-step'>
                      <div className='check-circle'>
                      </div>

                      <div style={{marginLeft: 10, width:'80%'}}>
                        <p style={{color: '#272838', fontSize: 16, fontFamily: 'Satoshi'}}>Verify Email</p>
                        <p style={{color: '#272838', fontSize: 12, fontFamily: 'Inter'}}>Verify your email address</p>
                      </div>

                      <img style={{height: 15}} src={chevron} alt='chevron icon'/>
                  </div>
                  <div className='sign-up-step'>
                      <div className='check-circle'>
                      </div>

                      <div style={{marginLeft: 10, width:'80%'}}>
                        <p style={{color: '#272838', fontSize: 16, fontFamily: 'Satoshi'}}>Business overview enquiries</p>
                        <p style={{color: '#272838', fontSize: 12, fontFamily: 'Inter'}}>Please provide some answers to the questions </p>
                      </div>

                      <img style={{height: 15}} src={chevron} alt='chevron icon'/>
                  </div>
              </div>
            }
        </div>

        <div className='auth-right-container'>

          <div style={{ position: 'absolute', left: '10%', top: '10%'}}>

            <h1 className='advidity-header'>Welcome to advidity</h1>
            <p className='advidity-subheader'>Please select the one that suits you better</p>
           

            <div className='tab-container' style={{width: 217}}>
              <div className={tab=== 'Sign Up' ? 'tab-active' : 'tab'} onClick={() => setTab("Sign Up")}>
                <p>Sign Up</p>
              </div>
              <div className={tab=== 'Log In' ? 'tab-active' : 'tab'} onClick={() => setTab("Log In")}>
                <p>Log In</p>
              </div>
            </div>

           {tab === 'Log In' ? 
           <div style={{display: 'flex', flexDirection: 'column', marginTop: 30}}>
              <label>Email Address</label>
              <input type='email' style={{width: 512}} className='advidity-input' value={email} onChange={(e) => setEmail(e.target.value)}/>

              <label>Password</label>
              <div className="wrapper">
                  <input type={showPassword ? 'text' : 'password'} style={{width: 512, flex: 1}} className='advidity-input'  
                  value={password} onChange={(e) => setPassword(e.target.value)}/>
                  <img className="auth-input-icon" src={showPassword ? eyeOpen : eyeClosed} alt='Password icon' onClick={() => setShowPassword(!showPassword)}/>
              </div> 

              <p style={{color:'#272838', textAlign:'right'}} onClick={resetPassword}>Forgotten password?</p>
              
              <button style={{width: 120}} className='primary-btn' onClick={login}>Sign in</button>

              <p>Don't have an account? <span onClick={() => setTab("Sign Up")}>Sign Up</span></p>
            </div>
            : 

            <CreateAccount 
            setTab={setTab}
            accountType={accountType} setAccountType={setAccountType}
            
            />

            }
           
            </div>

        </div>

     
    </div>
  )
}
