import React, { useState} from 'react'
import './Competition.css'
import { toast } from 'react-toastify'
import axios from 'axios'
import {useNavigate} from 'react-router-dom'

export default function Competition() {

    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [jobTitle, setJobTitle] = useState("")
    const [businessName, setBusinessName] = useState("")
    const [overview, setOverview] = useState("")
    const [challenges, setChallenges] = useState("")
    const [why, setWhy] = useState("")
    const [helpRequired, setHelpRequired] = useState("")

    const [loading, setLoading] = useState("")

    let navigate = useNavigate()

    const handleSubmit = (e) => {
        e.preventDefault()

        if(!name || !email || !jobTitle || !businessName || !overview || !challenges || !why || !helpRequired){
            toast.error("Please add all fields")
        } else if( overview.length > 500 || challenges.length > 500 || why.length > 500 || helpRequired.length > 300){
            toast.error("Too many characters")
        } else {
            setLoading(true)
            let payload = {
                name,
                email,
                jobTitle,
                businessName,
                overview,
                challenges,
                why,
                helpRequired
            }

            //handle http request
            axios.post(`${process.env.REACT_APP_API_URL}/competition-entry`, payload)
            .then(() => {
                clearFields()
                setLoading(false)
                navigate('/success')
            })
            .catch((e) => {
                toast.error("Oops something went wrong!")
                console.log(e)
            })
        }

    }

    const clearFields = () => {
        setName("");
        setEmail("");
        setJobTitle("");
        setBusinessName("");
        setOverview("");
        setChallenges("");
        setWhy("");
        setHelpRequired("")
    }

  return (
    <div id='competition'>
        <div id='page-overlay'/>

        <div id='comp-info'>
            <h1 style={{color: '#F3DE8A'}}>Apply Now for Our Exclusive Growth Partner Program!</h1>

            <p>Pitch your groundbreaking idea for a chance to win <b>FREE</b> credits and kickstart your business journey.</p>

            <p>Seize the opportunity to:</p>
            <ul >
                <li>Access essential resources for turning your vision into reality.</li>
                <li>Benefit from mentorship provided by S.theagency, coupled with valuable industry insights.</li>
                <li>Connect with a vibrant community of like-minded entrepreneurs.</li>
            </ul>

            <p>Submit your pitch now for a chance to win FREE credits for Advidity and set your business on the path to thriving success!</p>


            <p>Ready to Transform Your Business? Submit Your Pitch Now! Don't miss this opportunity to elevate your business.</p>


            <p>Join our Growth Partner Program. Deadline is 15th June 2024 Good luck!!</p>


        </div>


        <form id='comp-form'>
            <input placeholder='Full Name' value={name} onChange={(e) => setName(e.target.value)}/>
            <input placeholder='Work Email' value={email} onChange={(e) => setEmail(e.target.value)}/>
            <input placeholder='Job Title' value={jobTitle} onChange={(e) => setJobTitle(e.target.value)}/>
            <input placeholder='Business Name' value={businessName} onChange={(e) => setBusinessName(e.target.value)}/>
            <textarea placeholder='Business Overview' value={overview} onChange={(e) => setOverview(e.target.value)}/>
            <p className='txt-limit' style={overview.length > 500 ? {color: 'crimson'} : null}>{overview.length}/500</p>
            <textarea placeholder='What are your biggest challenges?' value={challenges} onChange={(e) => setChallenges(e.target.value)}/>
            <p className='txt-limit' style={challenges.length > 500 ? {color: 'crimson'} : null}>{challenges.length}/500</p>
            <textarea placeholder='Why would you like to win the growth partner credits with s.theagency x advidity?' value={why} onChange={(e) => setWhy(e.target.value)}/>
            <p className='txt-limit' style={why.length > 500 ? {color: 'crimson'} : null}>{why.length}/500</p>
            <textarea placeholder='What areas of your business do you need help with?' value={helpRequired} onChange={(e) => setHelpRequired(e.target.value)}/>
            <p className='txt-limit' style={helpRequired.length > 500 ? {color: 'crimson'} : null}>{helpRequired.length}/300</p>
            <button id='comp-submit' onClick={handleSubmit} disabled={loading}>{loading ? 'Submitting' : 'Submit'}</button>
        </form>

    </div>
  )
}
