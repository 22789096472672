import React from 'react'
import Lottie from 'lottie-react'
import animationData from '../../Assets/Animations/success.json';
import './Success.css'

export default function Success() {

  return (
    <div id='success'>
        <div  id='page-overlay'/>


        <div id='success-info' >
            <Lottie style={{width: 300, maxWidth: '90%'}} animationData={animationData} loop={false} />

            <h2>Congratulations on Applying to Our Growth Partner Program Competition!</h2>

            <p>You've taken the first step toward transforming your business with the help of advidity. Your pitch is now in our hands, and we're excited to witness innovation and passion you bring to the table.</p>

            <h3>What's next?</h3>

            <p>Our expert panel will carefully review each submission. Successful candidates will be notified via email, so keep an eye on your inbox.</p>

            <p>In the meantime you can explore more of <a href='https://s-theagency.com/' target={'_blank'} style={{color: '#eb9486'}}>S.theagency</a> and keep a look out in your inbox for the official launch of advidity.</p>

            <p>Stay tuned for updates!</p>
        </div>

    </div>
  )
}
