import React, { useState} from 'react'
import './Auth.css'
import tick from '../../Assets/icons/check.png'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'

export default function CreateAccount({setTab, accountType, setAccountType, }) {


  return (
    <div style={{display: 'flex', flexDirection: 'column', marginTop: 30}}>

            <div className={accountType === "Business" ? 'profile-choice-active' : 'profile-choice'} onClick={() => setAccountType("Business")}>
                <div className='profile-choice-square'>

                </div>

                <div>
                    <p className='advidity-header' style={{fontSize: 26, margin: 0}}>Sign up as a Business</p>
                    <p className='advidity-subheader' style={{fontSize: 16, margin: 0, marginTop: 10}}>Identify and train entry-level freelancers, collaborating with their diverse skills and youthful minds on a range of projects</p>

                </div>

                <div className='check-circle'>
                    {accountType === "Business" && <img style={{height: 20}} src={tick} alt='tick icon'/>}
                </div>
            </div>  

            <div className={accountType === "Freelancer" ? 'profile-choice-active' : 'profile-choice'}  onClick={() => setAccountType("Freelancer")}>
                <div className='profile-choice-square'>

                </div>
                <div>
                    <p className='advidity-header' style={{fontSize: 26, margin: 0}}>Sign up as a Freelancer</p>
                    <p className='advidity-subheader' style={{fontSize: 16, margin: 0, marginTop: 10}}>Gain industry insights and hands-on training as an entry-level creative, collaborating and networking for practical experience.</p>

                </div>

                <div className='check-circle'>
                    {accountType === "Freelancer" && <img style={{height: 20}} src={tick} alt='tick icon'/>}
                </div>
            </div>   

            <p style={{fontSize: 16}} className='advidity-subheader'>Already have an account? <span onClick={() => setTab("Log In")}>Log In</span></p>           
        
            {accountType && 
            <Link to={accountType === 'Business' ? '/create-business-account' :'/create-freelancer-account' }>
                <button style={{width: 100, marginTop: 30}} className='primary-btn'>
                    Continue
                </button>
            </Link>}

         
 
    </div>
  )
}
