import React, { useState, useEffect } from 'react'
import './Auth.css'
import { auth } from '../../Firebase';
import { toast } from 'react-toastify'
import { useAuth } from '../../Context/AuthContext';
import eyeOpen from '../../Assets/icons/show.png'
import eyeClosed from '../../Assets/icons/hide.png'
import tick from '../../Assets/icons/check.png'
import chevron from '../../Assets/icons/chevron.png'
import axios from 'axios'
import { createUserWithEmailAndPassword, sendEmailVerification, signOut } from "firebase/auth";
import { useNavigate} from 'react-router-dom'

export default function CreateBusinessAccountContinued() {
    let navigate = useNavigate();

    //Create account state
    let accountType = "Business"
    //Tab 1
    const [businessName, setBusinessName] = useState("")
    const [addressLineOne, setAddressLineOne] = useState("")
    const [addressLineTwo, setAddressLineTwo] = useState("")
    const [town, setTown] = useState("")
    const [postcode, setPostcode] = useState("")

    //Tab 2
    const [email, setEmail] = useState("")
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [number, setNumber] = useState("+44")
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [agree, setAgree] = useState(false)

    const [showPassword, setShowPassword] = useState(false)
    const [showPasswordConfirm, setShowPasswordConfirm] = useState(false)
    const [allFieldsComplete, setAllFieldsComplete] = useState(false)
    const [step, setStep] = useState(1)
    const [saving, setSaving] = useState(false)


   useEffect(() => {
       if(businessName && addressLineOne && addressLineTwo && town && postcode && email && firstName && lastName && number && password && confirmPassword) {
           setAllFieldsComplete(true)
       } else {
           setAllFieldsComplete(false)
       }

   }, [businessName, addressLineOne, addressLineTwo, town, postcode, email, firstName, lastName, number, password, confirmPassword])
        
    const createAccount = () => {

       
       if(!allFieldsComplete) {
            toast.error("Please add all fields")
        } else if(password !== confirmPassword){
            toast.error("Passwords don't match")
        } else if(password.length < 6){
            toast.error("Password must be more than 6 characters")
        } else if(!agree){
            toast.error("Please agree to terms")
        } else {
            setSaving(true)
            createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                const user = userCredential.user;

                axios.post(`${process.env.REACT_APP_API_URL}/business/create-business-user`, 
                {_id: user.uid, 
                accountType, 
                businessName, 
                addressLineOne, 
                addressLineTwo, 
                town, 
                postcode, 
                email, 
                firstName, 
                lastName, 
                number
                })
                .then(()=>{
                setSaving(false)
                toast.success("Account created successfully!")
                sendEmailVerification(user)
                .then(() => {
                    //Sign out user to allow role to propogate on token
                    signOut(auth)
                    .then(() =>
                    navigate('/login'))
                })
            
                })
                .catch((e)=>{
                console.log(e)
                toast.error("Oops, something went wrong")
                setSaving(false)

                })
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          toast.error(errorMessage)
          setSaving(false)

          // ..
        });
        }

    }

  return (
    <div id='auth-page'>
        <div id='auth-left-container'>
            <div className='auth-overlay' />
          <div style={{width: '100%', margin: '1rem auto', textAlign:'center', zIndex: 999}}>
            <p style={{ fontSize: 28, color: '#C47B70'}} >Advidity</p>
          </div>
            

          
              <div id='sign-up-steps'>
                <p style={{borderBottom: '1px solid #f1f1f1', padding: '1rem 0', fontFamily: 'Inter', fontSize: 14, color: 'grey'}}>COMPLETE THESE STEPS</p>

                  <div className='sign-up-step'>
                      <div className='check-circle'>
                          {accountType && <img style={{height: 20}} src={tick} alt='tick icon'/>}
                      </div>

                      <div style={{marginLeft: 10, width:'80%'}}>
                        <p style={{color: '#272838', fontSize: 16, fontFamily: 'Satoshi'}}>Choose a profile</p>
                        <p style={{color: '#272838', fontSize: 12, fontFamily: 'Inter'}}>Choose between a Studio, Teacher or Student profile</p>
                      </div>

                      <img style={{height: 15}} src={chevron} alt='chevron icon'/>
                  </div>
                  <div className='sign-up-step'>
                      <div className='check-circle'>
                          {allFieldsComplete && <img style={{height: 20}} src={tick} alt='tick icon'/>}
                      </div>

                      <div style={{marginLeft: 10, width:'80%'}}>
                        <p style={{color: '#272838', fontSize: 16, fontFamily: 'Satoshi'}}>Provide personal details</p>
                        <p style={{color: '#272838', fontSize: 12, fontFamily: 'Inter'}}>Kindly provide the answers to the input fields</p>
                      </div>

                      <img style={{height: 15}} src={chevron} alt='chevron icon'/>
                  </div>
                  <div className='sign-up-step'>
                      <div className='check-circle'>
                      </div>

                      <div style={{marginLeft: 10, width:'80%'}}>
                        <p style={{color: '#272838', fontSize: 16, fontFamily: 'Satoshi'}}>Verify Email</p>
                        <p style={{color: '#272838', fontSize: 12, fontFamily: 'Inter'}}>Verify your email address</p>
                      </div>

                      <img style={{height: 15}} src={chevron} alt='chevron icon'/>
                  </div>
                  <div className='sign-up-step'>
                      <div className='check-circle'>
                      </div>

                      <div style={{marginLeft: 10, width:'80%'}}>
                        <p style={{color: '#272838', fontSize: 16, fontFamily: 'Satoshi'}}>Business overview enquiries</p>
                        <p style={{color: '#272838', fontSize: 12, fontFamily: 'Inter'}}>Please provide some answers to the questions </p>
                      </div>

                      <img style={{height: 15}} src={chevron} alt='chevron icon'/>
                  </div>
              </div>
            
        </div>

        <div className='auth-right-container'>

          <div style={{ position: 'absolute', left: '10%', top: '10%'}}>
          <p className='advidity-subheader'>{step}/2</p>

            <h1 className='advidity-header'>Sign up to hire young talent</h1>
            <p className='advidity-subheader'>Please kindly provide your details</p>

           {step === 1 &&
            <div style={{display: 'flex', flexDirection: 'column', marginTop: 30}}>
                <label>Business name</label>
                <input type='email' style={{width: 512}} className='advidity-input' value={businessName} onChange={(e) => setBusinessName(e.target.value)}/>

                <label>Business Address line 1</label>
                <input type='text' style={{width: 512}} className='advidity-input' value={addressLineOne} onChange={(e) => setAddressLineOne(e.target.value)}/>


                <label>Address line 2</label>
                <input type='text' style={{width: 512}} className='advidity-input' value={addressLineTwo} onChange={(e) => setAddressLineTwo(e.target.value)}/>

                <label>Town/City</label>
                <input type='text' style={{width: 512}} className='advidity-input' value={town} onChange={(e) => setTown(e.target.value)}/>

                <label>Postcode</label>
                <input type='text' style={{width: 512}} className='advidity-input' value={postcode} onChange={(e) => setPostcode(e.target.value.toUpperCase())}/>

                <button className='primary-btn' onClick={() => setStep(2)}>Continue</button>
            </div>
            }
            {step === 2 &&
            <div style={{display: 'flex', flexDirection: 'column', marginTop: 30}}>
                <div className='advidity-row' >
                    <div style={{display: 'flex', flexDirection: 'column', marginTop: 30, marginRight: 30}}>
                        <label>First Name</label>
                        <input type='email' style={{width: 300}} className='advidity-input' value={firstName} onChange={(e) => setFirstName(e.target.value)}/>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', marginTop: 30}}>
                        <label>Last Name</label>
                        <input type='text' style={{width: 300}} className='advidity-input' value={lastName} onChange={(e) => setLastName(e.target.value)}/>
                    </div>
                </div>
               
             

                <div className='advidity-row' >
                    <div style={{display: 'flex', flexDirection: 'column', marginTop: 30, marginRight: 30}}>
                        <label>Email Address</label>
                        <input type='text' style={{width: 300}} className='advidity-input' value={email} onChange={(e) => setEmail(e.target.value)}/>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', marginTop: 30}}>
                        <label>Mobile Number</label>
                        <input type='text' style={{width: 300}} className='advidity-input' placeholder='inc +44' value={number} onChange={(e) => setNumber(e.target.value)}/>
                    </div>
                </div>

                <div className='advidity-row' >
                    <div style={{display: 'flex', flexDirection: 'column', marginTop: 30, marginRight: 30}}>
                        <label>Password</label>
                        <div className="wrapper">
                            <input type={showPassword ? 'text' : 'password'} style={{width: 300, flex: 1}} className='advidity-input'  
                            value={password} onChange={(e) => setPassword(e.target.value)}/>
                            <img className="auth-input-icon" src={showPassword ? eyeOpen : eyeClosed} alt='Password icon' onClick={() => setShowPassword(!showPassword)}/>
                        </div> 
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', marginTop: 30}}>
                        <label>Confirm Password</label>
                        <div className="wrapper">
                            <input type={showPasswordConfirm ? 'text' : 'password'} style={{width: 300, flex: 1}} className='advidity-input'  
                            value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)}/>
                            <img className="auth-input-icon" src={showPasswordConfirm ? eyeOpen : eyeClosed} alt='Password icon' onClick={() => setShowPasswordConfirm(!showPasswordConfirm)}/>
                        </div> 
                    </div>
                </div>

                <div className='advidity-row' style={{width: 700, margin: '20px 0', alignItems: 'baseline'}}>
                    <input style={{marginRight: 10}} type='checkbox' value={agree} onChange={() => setAgree(!agree)}/>
                    <p>Please note that by clicking on submit, you agree to the Terms and Conditions of Advidity. You can click on Terms and Conditions to read and come back to tick the box</p>
                </div>

                <div className='advidity-row' >
                    <button style={{marginRight: 30}} className='primary-btn' onClick={() => setStep(1)}>Back</button>
                    <button className='primary-btn' onClick={createAccount} disabled={saving}>{saving ? "Saving..." : "Sign Up"}</button>
                </div>

               

                
            </div>
            }

      
       
            </div>

        </div>

     
    </div>
  )
}
