import React, { useState} from 'react'
import img from '../../../Assets/icons/img-placeholder.png'
import { useAuth } from '../../../Context/AuthContext'
import { useUser} from '../../../Context/UserContext'
import axios from 'axios'
import { useNavigate} from 'react-router-dom'
import more from '../../../Assets/more.png'
import ContestPayment from './ContestPayment'

export default function ContestApplicantCard({application, getContestApplications, contestId}) {

  let { currentUser } = useAuth()
  let { userInfo } = useUser()

  const [popupOpen, setPopupOpen] = useState(false)
  const [paymentOpen, setPaymentOpen] = useState(false)

  let navigate = useNavigate()

  const sendMsg = () => {
    let payload = {
      businessId: currentUser.uid,
      businessName: currentUser.displayName,
      businessEmail: currentUser.email,
      businessRead: true,
      businessImg: userInfo.logo,
      freelancerName: application.name,
      freelancerEmail: application.email,
      freelancerId: application.applicantId,
      freelancerImg: application.applicantImg,
      freelancerRead: false,
      lastMsgAdded: new Date(),
    }

    axios.post(`${process.env.REACT_APP_API_URL}/chats/open-chat`, payload)
    .then((res) => {
      console.log(res.data)
      navigate(`/business/messages/${res.data._id}`)
    })
    .catch((e) => {
      console.log(e)
    })
  }

  const setStage = (stage) => {

    axios.post(`${process.env.REACT_APP_API_URL}/contests/set-job-stage`, {id: application._id, stage})
    .then((res) => {
      getContestApplications()
    })
    .catch((e) => {
      console.log(e)
    })
  }

  const handleWinner = (name) => {
    if(window.confirm(`Are you happy to make ${name} the winner?`)){
      setPaymentOpen(true)
    } else {
      return
    }
  }

  return (
    <div className='applicant-card' style={{height: 'auto', minWidth: 270}}>
        <div style={{display:'flex', alignItems: 'center',justifyContent:'space-between', borderBottom: '1px solid #f1f1f1', paddingBottom: 10}}>
            <img style={{marginRight: 10, height: 70, width: 70}} src={img} alt='Freelancer- icon'/>
            <div style={{width: 'calc(100% - 100px)'}}>
                <p className='advidity-header' style={{fontSize: 18}}>{application.name}</p>
                <p className='advidity-subheader' style={{fontSize: 16, margin: '5px 0'}}>{application.jobTitle}</p>
            </div>
            <div style={{position:'relative'}} onClick={() => setPopupOpen(!popupOpen)}>            
              <img className='pointer' style={{marginRight: 10, height: 30, width: 30}} src={more} alt='More- icon'/>
             {popupOpen && 
              <div style={{minWidth: 180, minHeight: 170, backgroundColor:'#f1f1f1', position:'absolute', right: 50, top: 0, borderRadius: 8, padding: 10, boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px'}}>
                <p style={{color: 'grey', marginBottom: 10}}>Move to</p>
                <div className='pointer' onClick={() => setStage('Received')} style={{backgroundColor: '#fafafa', padding: 5}}>
                  <p>Application Received</p>
                </div>
                <div className='pointer' onClick={() => setStage('Shortlisted')} style={{backgroundColor: '#f1f1f1', padding: 5}}>
                  <p>Shortlisted</p>
                </div>
                <div className='pointer' onClick={() => setStage('Rejected')} style={{backgroundColor: '#fafafa', padding: 5}}>
                  <p>Rejected</p>
                </div>
              </div>
              }
            </div>
              

        </div>
        <div className='advidity-row' style={{justifyContent:'space-between', flexWrap:'wrap'}}>
              
              {application.link && 
                <a href={application.link} target='_blank' className='job-chip' style={{width: '100%', height: 48}}>
                  <button style={{background: 'unset', border: 'unset'}}>View Work</button>
                </a>
              }
              {application.file && 
                <a href={application.file} target='_blank' className='job-chip' style={{width: '100%', height: 48}}>
                    <button  style={{background: 'unset', border: 'unset'}}>View Work</button>
                </a>
              }

            <button onClick={sendMsg} className='job-chip' style={{width: '100%', height: 48, border: 'unset', flexShrink: 0, backgroundColor: '#EB9486', color:'white'}}>Send Message</button>
            <button onClick={() => handleWinner(application.name)}  className='job-chip' style={{width: '100%', height: 48, border: 'unset', flexShrink: 0, backgroundColor: '#AFE1AF', color:'#1e1e1e'}}>Make Winner</button>

        </div>

        {paymentOpen && <ContestPayment setPaymentOpen={setPaymentOpen} contestId={contestId} application={application}/>}

        
    </div>
  )
}
