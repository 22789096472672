import React, { useState, useEffect} from 'react'
import './Jobs.css'
import JobsCreate from './JobsCreate'
import { useAuth } from '../../../Context/AuthContext'
import axios from 'axios'
import JobsList from './JobsList'
import ViewJob from './ViewJob'

export default function Jobs() {

  const { currentUser } = useAuth()

  const [tab, setTab] = useState(1)
  const [jobs, setJobs] = useState([])
  const [myJobs, setMyJobs] = useState([])
  const [activeJobs, setActiveJobs] = useState([])
  const [openJob, setOpenJob] = useState(false)
  const [viewJob, setViewJob] = useState(false)
  const [jobId, setJobId] = useState("")

  useEffect(() => {
    getMyJobs()
    getAllJobs()
  }, [])

  //Retrieve past and present jobs for my company
  const getMyJobs = () => {
    axios.post(`${process.env.REACT_APP_API_URL}/jobs/get-jobs-by-business`, { id: currentUser.uid})
    .then((res) => {
      let active = res.data.filter((job) => job.active !== false)
      setMyJobs(res.data)
      setActiveJobs(active)
    })
    .catch((e) => {
      console.log(e)
    })
  }
  //Retrive all active jobs from all companies
  const getAllJobs = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/jobs/get-all-active-jobs`)
    .then((res) => {
      setJobs(res.data)
    })
    .catch((e) => {
      console.log(e)
    })
  }

  return (
    <div className='business-outlet'>

      {openJob && <JobsCreate setOpenJob={setOpenJob} setTab={setTab} getMyJobs={getMyJobs}/>}
      {viewJob && <ViewJob setOpenJob={setViewJob} jobId={jobId}/>}

      <div id='jobs-dash-content'>
        <p className='advidity-header'>Jobs</p>
        <p className='advidity-subheader'>Find your preferred freelancers through job postings.</p>
        <button className='primary-btn' id='create-job-btn' onClick={() => setOpenJob(true)}>Create job post</button>

        <div className='tab-container' style={{width: 700, maxWidth: '100%'}}>
          <div className={tab === 1 ? 'tab-active' : 'tab'} onClick={() => setTab(1)}>
            <p>All Jobs</p>
            <div className='tab-square'>
                <p>{jobs.length}</p>
              </div>
          </div>
          <div className={tab === 2 ? 'tab-active' : 'tab'} onClick={() => setTab(2)}>
            <p>Your Posted Jobs</p>
            <div className='tab-square'>
                <p>{myJobs.length}</p>
            </div>
          </div>
          <div className={tab === 3 ? 'tab-active' : 'tab'} onClick={() => setTab(3)}>
            <p>Active Jobs</p>
            <div className='tab-square'>
                <p>{activeJobs.length}</p>
            </div>
          </div>
       
        </div>

       
        {tab === 1 && <JobsList jobs={jobs} viewJob={viewJob} setViewJob={setViewJob} setJobId={setJobId} />}

        {tab === 2 && <JobsList jobs={myJobs} viewJob={viewJob} setViewJob={setViewJob} setJobId={setJobId}/>}

        {tab === 3 && <JobsList jobs={activeJobs} viewJob={viewJob} setViewJob={setViewJob} setJobId={setJobId}/>}


      </div>
    </div>
  )
}
