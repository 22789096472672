import React, { useState, useEffect} from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import './LearningPortal.css'
import YouTube from 'react-youtube';
import { toast } from 'react-toastify';

export default function CompleteCourse() {

    let { id } = useParams()

    const [questions, setQuestions] = useState([])
    const [activeQuestion, setActiveQuestion] = useState("")
    const [selectedAnswer, setSelectedAnswer] = useState([])


    useEffect(() => {
      getMyCourse()
    }, [])

    useEffect(() => {
      getActiveQuestion()
    }, [questions])

    const getMyCourse = () => {
      axios.post(`${process.env.REACT_APP_API_URL}/course/get-my-course`, {courseId: id})
      .then((res) => {
        console.log(res.data)
        setQuestions(res.data.questions)
      })
      .catch((e) => {
        console.log(e)
      })
    }

    const getActiveQuestion = () => {
      let currentQuestion = questions.find((question) => question.complete !== true )
      setActiveQuestion(currentQuestion)
    }

    const submitAnswer = () => {
      if(selectedAnswer[0] !== activeQuestion.correctAnswer){
        toast.error("Wrong answer, please watch the video and try again")
        console.log(activeQuestion)
      } else {
        toast.success("Correct!")
        setSelectedAnswer([])

        //complete question 
        const updatedQuestions = questions.map(obj =>
          obj.question === activeQuestion.question ? { ...obj, complete: true } : obj
        );
        axios.post(`${process.env.REACT_APP_API_URL}/course/submit-answer`, {courseId: id, questions: updatedQuestions})
        .then((res) => {
          getMyCourse()
        })
        .catch((e) => {
          console.log(e)
        })
      }

    }

    const handleSelect = (e) => {
      if(e.target.checked){
        setSelectedAnswer((prev) => [...prev, e.target.value])

      } else {
        //remove from answers array
        let updatedArr = selectedAnswer.filter((answer) => answer !== e.target.value)
        setSelectedAnswer(updatedArr)
      }
    }

    const opts = {
      height: '390',
      width: '640',
      playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autoplay: 1,
      },
    };

    const onReady = (event) => {
      // access to player in all event handlers via event.target
      event.target.pauseVideo();
    }

  return (
    <div className='outlet' style={{flexDirection: 'row'}}>

      <div id='question-list'>
        {questions?.map((question, i) => {
          return <div key={i} className='question-number-active'>
                    <p>Question {i + 1}</p>
                </div>
        })}

      </div>

      <div id='question-container'>
        {console.log(activeQuestion)}
        {activeQuestion?.video && <YouTube videoId="eEzD-Y97ges" opts={opts} onReady={onReady} />}
        <h2>{activeQuestion?.question}</h2>

        {activeQuestion?.answers && activeQuestion?.answers.map((option, i) => {
          return <div className='answer-container' key={i}>
                  <input className='question-checkbox' type='checkbox' name="answer" value={option} onChange={(e) => handleSelect(e)}/>
                  <label>{option}</label>
                </div>
        })}
          <div>
            <button id='submit-answer' onClick={submitAnswer}>Submit answer</button>
            <button id='next-question'>Back</button>
          </div>
        {console.log(selectedAnswer)}
      </div>
    </div>
  )
}
