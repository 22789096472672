import React, { useState, useEffect} from 'react'
import './Portfolio.css'
import Modal from 'react-modal';
import axios from 'axios'
import { useAuth } from '../../../Context/AuthContext'
import PortfolioModal from './PortfolioModal';
import { toast } from 'react-toastify'
import { v4 as uuidv4 } from 'uuid';

//firebase
import { storage } from '../../../Firebase'
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

export default function Portfolio() {

    let { currentUser } = useAuth()

    const [ manuallyAdded, setManuallyAdded ] = useState([])
    const [modalIsOpen, setIsOpen] = useState(false);
    const [porfolioModalOpen, setPorfolioModalOpen] = useState(false);

    //modal state
    const [title, setTitle] = useState("")
    const [description, setDescription] = useState("")
    const [link, setLink] = useState("")
    const [portfolioImg, setPortfolioImg] = useState("https://firebasestorage.googleapis.com/v0/b/zest-b90d0.appspot.com/o/portfolio.png?alt=media&token=3162ab60-dda1-4b42-bb4d-68897dd36ab2")
    const [portfolio, setPortfolio] = useState(null)

    useEffect(() => {
      getPortfolios()
    }, [])

    const getPortfolios = () => {
      axios.post(`${process.env.REACT_APP_API_URL}/portfolio/get-portfolio-items`, {freelanceId: currentUser.uid})
      .then((res) => {
        setManuallyAdded(res.data)
        console.log(res.data)
      })
      .catch((e) => {
        console.log(e)
      })

    }

    function openModal() {
      setIsOpen(true);
    }
  
    function closeModal() {
      setIsOpen(false);
    }

    const clearModal = () => {
      setTitle("");setDescription("");setLink("")
      setPortfolioImg("https://firebasestorage.googleapis.com/v0/b/zest-b90d0.appspot.com/o/portfolio.png?alt=media&token=3162ab60-dda1-4b42-bb4d-68897dd36ab2");
    }

    const savePortfolio = () => {

      let payload = {freelanceId: currentUser.uid, title, description, link, img: portfolioImg, points: 20}

      if(!title || !description){
        toast.error("Please add title and description")
      } else {
        axios.post(`${process.env.REACT_APP_API_URL}/portfolio/create-portfolio-item`, payload)
        .then((res) => {
          console.log(res.data)
          clearModal()
          getPortfolios()
          closeModal()
          addPoints(20)
        })
        .catch((e) => {
          console.log(e)
        })
      }

      
    }

    const addPoints = (points) => {
      axios.post(`${process.env.REACT_APP_API_URL}/freelance/add-points`, {_id: currentUser.uid, points})
      .then(() => {
        toast.success("20 Points added!")
      })
      .catch((e) => {
        console.log(e)
      })
    }

    
     const addImg = (file) =>{

      let uniqueId = uuidv4()

      const imgRef = ref(storage, `freelancers/portfolio/${currentUser.uid}/${uniqueId}`);

      uploadBytes(imgRef, file)
      .then(() => {
 
          getDownloadURL(imgRef)
          .then((url) => {
            setPortfolioImg(url)
          })
          .catch((error) => {
            console.log(error)
            toast.error("Error")
          });
        })
        .catch((error) => {
            console.log(error)
        });
     }


  return (
    <div className='business-outlet' >
         <div id='business-dash-content'>
            <p className='advidity-header'>Portfolio</p>
            <p className='advidity-subheader'>Build points and showcase your work to businesses</p>


        {manuallyAdded.length < 5 && 
        <div className='add-container' style={{margin:'1rem', width: 250}} onClick={openModal}>
            <p style={{color:'grey'}}>Add portfolio item +</p>
        </div>}

        <div style={{display: 'flex', flexWrap:'wrap'}}>

        {manuallyAdded.length < 1 ? 
        <p>No portfolio items added</p>
        :
        manuallyAdded.map((item, i) => {
          return (
            <div key={i} className='portfolio-item' style={{backgroundImage: `url(${item.img})`, backgroundPosition:'center', backgroundSize:'cover'}}
            onClick={() => {
              setPortfolio(item); 
              setPorfolioModalOpen(true)
            }}
            >

              <div className='purple-gradient'>

                <h3 className='advidity-header' style={{color:'white', fontSize: 16}}>{item.title}</h3>
              </div>
            </div>
          )
        }) 
        }   
        </div>

        
        <PortfolioModal
         porfolioModalOpen={porfolioModalOpen} 
         setPorfolioModalOpen={setPorfolioModalOpen} 
         portfolio={portfolio} 
         setPortfolio={setPortfolio}
         getPortfolios={getPortfolios}
         />
       

        <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <h2 className='advidity-header'>Add new portfolio item</h2>

        <input className='advidity-input' placeholder='Portfolio title/name' value={title} onChange={(e) => setTitle(e.target.value)}/>
        <textarea className='advidity-input' placeholder='Brief description of work' style={{resize:'none', paddingTop: 10, height: 100}} value={description} onChange={(e) => setDescription(e.target.value)}/>

        <input className='advidity-input' placeholder='Link (optional)' value={link} onChange={(e) => setLink(e.target.value)}/>

        <label className="advidity-subheader">Upload image (optional)</label>

        <div className='add-container' style={{margin:'0 auto', position:'relative'}}>
          <img style={{width: '100%', height: '100%', borderRadius: 30, padding: 10}} src={portfolioImg}/>
          <input type="file" accept="image/*" id='img-input'  onChange={(e)=>addImg(e.target.files[0])}/>

        </div>

        <div style={{display:'flex', justifyContent:'space-evenly'}}>
          <button className='primary-btn' onClick={savePortfolio}>Add</button>

          <button className='secondary-btn' onClick={closeModal}>close</button>
        </div>
        
        
      </Modal>
      </div>

    </div>
  )
}
const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      display:'flex',
      flexDirection:'column',
      backgroundColor: '#fefefe',
      borderRadius: '30px',
      boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
      textAlign:'center',
    },
  };
  