import React, { useState, useEffect} from 'react'
import axios from 'axios'
import PaymentForm from './PaymentForms'
import stripePromise from '../../../Stripe'
import {  Elements} from '@stripe/react-stripe-js';
import { useAuth } from '../../../Context/AuthContext';

export default function ContestPayment({setPaymentOpen, contestId, application}) {

  const [title, setTitle] = useState("")
  const [amount, setAmount] = useState("")
  const [primarySkill, setPrimarySkill] = useState("")
  const [connectAccount, setConnectAccount] = useState("")
  const [paymentSuccess, setPaymentSuccess] = useState(false);

  let { currentUser } = useAuth()

  useEffect(() => {
    if(contestId && application){
        //Retrieve job info 
        axios.post(`${process.env.REACT_APP_API_URL}/contests/get-contest-by-id`, {contestId})
        .then((res) => {
            let {title, prizeMoney, primarySkill } = res.data;
            setTitle(title);
            setAmount(prizeMoney)
            setPrimarySkill(primarySkill)
           

        })
        .catch((e) => {
            console.log(e)
        })
        //Retrieve conect account 
        getApplicantInfo()

    } else {
        return
    }

  }, [contestId, application])

  const getApplicantInfo = () => {
    let id= application.applicantId

    axios.post(`${process.env.REACT_APP_API_URL}/freelance/get-user-info`, {_id: id})
    .then((res) => {
        setConnectAccount(res.data.connectData.accountId)
    })
    .catch((e) => {
        console.log(e)
    })

  }

  return (
    <div id='create-contest'>
      
        <div id='create-contest-container'>
        {!paymentSuccess ?
          <>
            <button onClick={() => setPaymentOpen(false)} id='close-contest-btn'>x</button>

            <div className='create-contest-header' style={{display: 'flex', alignItems:'center'}}>
                <div style={{height: 48, width: 48, borderRadius: 8, backgroundColor: '#f1f1f1', marginRight: 20}}>

                </div>
                <div>
                  <p className='advidity-subheader' style={{fontSize: 16, margin: 0}}>{title}</p>
                  <p className='advidity-header' style={{fontSize: 22, }}>{primarySkill}</p>
                </div>
                
             </div>
              <div className='new-contest-container' style={{padding: '3rem'}}>
                <p >Winner</p>

                <p className='advidity-header'>{application.name}</p>

                <p className='advidity-subheader' style={{fontSize: 14}}>Once the payment has been successfully completed, the winner will be notified and will be required to send any relevant files for the submission.</p>

                {application.link && 
                <a href={application.link} target='_blank' className='job-chip' style={{width: '100%', height: 48}}>
                  <button style={{background: 'unset', border: 'unset'}}>View Winning Submission</button>
                </a>
              }
              {application.file && 
                <a href={application.file} target='_blank' className='job-chip' style={{width: '100%', height: 48}}>
                    <button  style={{background: 'unset', border: 'unset'}}>View Winning Submission</button>
                </a>
              }

                <p className='advidity-subheader' style={{fontSize: 14, margin: '40px 0 10px 0'}}>Amount due</p>
                <p className='advidity-header' style={{fontSize: 20, margin: 0}}>£{amount}</p>

            
                    <Elements stripe={stripePromise}>
                        <PaymentForm 
                            userId={currentUser.uid}
                            name={currentUser.displayName}
                            email={currentUser.email}
                            amount={amount}
                            connectedAccountId={connectAccount}
                            setPaymentSuccess={setPaymentSuccess}
                        />
                    </Elements>
                
                              
            </div>
            </>

            : 
            <div>
              <button onClick={() => setPaymentOpen(false)} id='close-contest-btn'>x</button>

              <p className='advidity-header' style={{textAlign:'center'}}>Successfully Paid!</p>
              <p className='advidity-subheader' style={{textAlign:'center'}}>The winner has now been notified</p>
            </div>
            }
        </div>
        
    </div>
  )
}
