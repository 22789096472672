import React, { useState} from 'react'
import { Link } from 'react-router-dom'
import './Dashboard.css'
import { useAuth} from '../../Context/AuthContext'
import { useOutletContext } from "react-router-dom";
import NotificationAndMessages from './NotificationAndMessages';
import computer from '../../Assets/course-img.png'

export default function BusinessDashboard() {

  let { currentUser } = useAuth()

  const [tab, setTab] = useState(1)
  const { setActive } = useOutletContext();

  return (
    <div className='business-outlet'>

      <div id='business-dash-content'>
          <p style={{fontFamily:'Satoshi', fontSize: 32, letterSpacing: -0.5, color: '#272838'}}>Welcome, <span>{currentUser.displayName}</span></p>
          <p style={{fontFamily:'Inter', fontSize: 18, letterSpacing: -0.5, color: 'grey', margin: '1rem 0'}}>Find your preferred gigs, jobs, contests and many more</p>

          <div className='create-contest-box'>
              <p style={{fontFamily:'Satoshi', fontSize: 28, letterSpacing: -0.5, color: '#fff'}}>Build your profile</p>
              <p style={{fontFamily:'Inter', fontSize: 18, letterSpacing: -0.5, color: '#fff', margin: '1rem 0'}}>And get access to more features</p>
              <Link to='settings' onClick={() => setActive('Contests')}>
                  <button id='create-contest-btn'>Complete Profile</button>
              </Link>
              <img style={{height: "90%", position: 'absolute', right: '-1%', bottom: '-5%'}} src={computer} alt='"Courses computer'/>

          </div>

          <div id='business-dash-tabs' >
            <div className={tab === 1 ? 'tab-active' : 'tab'} onClick={() => setTab(1)}>
              <p className={tab === 1 ? 'business-dash-title-active' : 'business-dash-title'}>Checklist</p>
            </div>
            <div className='business-dash-tab' onClick={() => setTab(2)}>
              <div className={tab === 2 ? 'tab-active' : 'tab'}>
                <p className={tab === 2 ? 'business-dash-title-active' : 'business-dash-title'}>Featured Jobs</p>
                <div className='tab-square'>
                  <p>5</p>
                </div>{console.log(tab)}
              </div>
            </div>
            <div className='business-dash-tab' onClick={() => setTab(3)}>
              <div className={tab === 3 ? 'tab-active' : 'tab'}>
                <p className={tab === 3 ? 'business-dash-title-active' : 'business-dash-title'}>New Jobs</p>
                <div className='tab-square'>
                  <p>4</p>
                </div>
              </div>
            </div>
            <div className='business-dash-tab' onClick={() => setTab(4)}>
              <div className={tab === 4 ? 'tab-active' : 'tab'}>
                <p className={tab === 4 ? 'business-dash-title-active' : 'business-dash-title'}>Top Freelancers</p>
                <div className='tab-square'>
                  <p>2</p>
                </div>
              </div>
            </div>
          </div>

      {tab ===1 && <>
          <div style={{width: '100%', display:'flex', flexWrap:'wrap', justifyContent: 'space-between'}}>
            <div className='dash-contests' style={{width: '54%'}}>
                <p className='tab-content-header'>POINTS</p>
                <p className='tab-content-title'>Get referral points when you refer a friend</p>
                <Link to='points' onClick={() => setActive('Points')} className='tab-content-btn'>
                    <button className='tab-content-btn'>Get referral link</button>
                </Link>
            </div>
            <div className='dash-contests' style={{width: '44%'}}>
                <p className='tab-content-header'>COURSE</p>
                <p className='tab-content-title'>Register for your first course</p>
                <Link to='learning-portal' onClick={() => setActive('Courses')} className='tab-content-btn'>
                    <button className='tab-content-btn'>View Courses</button>
                </Link>
            </div>

          </div>

          
          <div className='dash-contests'>
              <p className='tab-content-header'>CONTESTS</p>
              <p className='tab-content-title'>Participate in your first contest</p>
              <Link to='contests' onClick={() => setActive('Contests')} className='tab-content-btn'>
                  <button className='tab-content-btn'>View Contest</button>
              </Link>
          </div>
          </>}

          {tab === 2 && 
          <>
            <div id='top-freelance-scroller'>
              <div className='freelance-overview-container'>

                <img className='freelance-overview-img' src={'https://firebasestorage.googleapis.com/v0/b/advidity-facb4.appspot.com/o/woman-headshot-outdoors.jpeg?alt=media&token=f4e680f2-aecd-46ba-bb30-6593d2c1ffa8'} alt='Freelancer img'/>
                <p style={{fontFamily: 'Satoshi', fontSize: 16, letterSpacing: -0.5, margin: '1rem 0'}}>Anita Scott</p>
                <p style={{fontFamily: 'Inter', fontSize: 14, letterSpacing: -1, color:'#75788D'}} className='freelance-overview-role'>Graphic Designer</p>

                <p style={{fontFamily: 'Inter', fontSize: 14, letterSpacing: -1, color:'#75788D', margin: '1rem 0'}}>
                  Passionate graphic designer skilled in visual storytelling, adept at turning ideas into impactful designs using industry standard software.
                </p>

                <button className='send-msg-btn'>Send Message</button>
              </div>
              <div className='freelance-overview-container'>
                <img className='freelance-overview-img' src={'https://firebasestorage.googleapis.com/v0/b/advidity-facb4.appspot.com/o/woman-headshot-outdoors.jpeg?alt=media&token=f4e680f2-aecd-46ba-bb30-6593d2c1ffa8'} alt='Freelancer img'/>
                  <p style={{fontFamily: 'Satoshi', fontSize: 16, letterSpacing: -0.5, margin: '1rem 0'}}>Anita Scott</p>
                  <p style={{fontFamily: 'Inter', fontSize: 14, letterSpacing: -1, color:'#75788D'}} className='freelance-overview-role'>Graphic Designer</p>

                  <p style={{fontFamily: 'Inter', fontSize: 14, letterSpacing: -1, color:'#75788D', margin: '1rem 0'}}>
                    Passionate graphic designer skilled in visual storytelling, adept at turning ideas into impactful designs using industry standard software.
                  </p>

                  <button className='send-msg-btn'>Send Message</button>
              </div>
              <div className='freelance-overview-container'>
                <img className='freelance-overview-img' src={'https://firebasestorage.googleapis.com/v0/b/advidity-facb4.appspot.com/o/woman-headshot-outdoors.jpeg?alt=media&token=f4e680f2-aecd-46ba-bb30-6593d2c1ffa8'} alt='Freelancer img'/>
                <p style={{fontFamily: 'Satoshi', fontSize: 16, letterSpacing: -0.5, margin: '1rem 0'}}>Anita Scott</p>
                <p style={{fontFamily: 'Inter', fontSize: 14, letterSpacing: -1, color:'#75788D'}} className='freelance-overview-role'>Graphic Designer</p>

                <p style={{fontFamily: 'Inter', fontSize: 14, letterSpacing: -1, color:'#75788D', margin: '1rem 0'}}>
                  Passionate graphic designer skilled in visual storytelling, adept at turning ideas into impactful designs using industry standard software.
                </p>

                <button className='send-msg-btn'>Send Message</button>
              </div>
              <div className='freelance-overview-container'>
                <img className='freelance-overview-img' src={'https://firebasestorage.googleapis.com/v0/b/advidity-facb4.appspot.com/o/woman-headshot-outdoors.jpeg?alt=media&token=f4e680f2-aecd-46ba-bb30-6593d2c1ffa8'} alt='Freelancer img'/>
                <p style={{fontFamily: 'Satoshi', fontSize: 16, letterSpacing: -0.5, margin: '1rem 0'}}>Anita Scott</p>
                <p style={{fontFamily: 'Inter', fontSize: 14, letterSpacing: -1, color:'#75788D'}} className='freelance-overview-role'>Graphic Designer</p>

                <p style={{fontFamily: 'Inter', fontSize: 14, letterSpacing: -1, color:'#75788D', margin: '1rem 0'}}>
                  Passionate graphic designer skilled in visual storytelling, adept at turning ideas into impactful designs using industry standard software.
                </p>

                <button className='send-msg-btn'>Send Message</button>
              </div>
            </div>
            <button id='more-candidates-btn'>
              See More Candidates
            </button>
          </>
          }

        {tab === 3 && 
        <div id='popular-jobs-container'>
          <div className='popular-job'>
            <div style={{ display:'flex', flexWrap:'wrap', justContents: 'space-between', alignItems: 'center'}}>
              <img className='freelance-overview-img' src={'https://firebasestorage.googleapis.com/v0/b/advidity-facb4.appspot.com/o/woman-headshot-outdoors.jpeg?alt=media&token=f4e680f2-aecd-46ba-bb30-6593d2c1ffa8'} alt='Freelancer img'/>
              <div style={{width: '70%', padding: '0 2rem'}}>
                  <p style={{fontFamily: 'Satoshi', fontSize: 16, color: '#1e1e1e', letterSpacing: -1}}>Video Director</p>
                  <div style={{display: 'flex', flexDirection:'row', alignItems :'center'}}>
                    <p style={{fontFamily: 'Inter', fontSize: 14, color: '#75788D', letterSpacing: -1}}>Advidity</p>
                    <div style={{height: 6, width: 6, backgroundColor: '#1e1e1e', borderRadius: 3, margin: '0 1rem'}}/>
                    <div className='job-chip'>
                      <p>Full-time</p>
                    </div>
                    <div className='job-chip'>
                      <p>Remote</p>
                    </div>
                    <div className='job-chip'>
                      <p>£48,000</p>
                    </div>
                  </div>
              </div>

              <div>
                <p style={{fontFamily: 'Satoshi', fontSize: 14, color: '#1e1e1e', letterSpacing: -1}}>Birmingham, UK</p>
                <p style={{fontFamily: 'Inter', fontSize: 12, color: '#75788D', letterSpacing: -1}}>Posted 5 mins ago</p>

              </div>
            </div>
            <p style={{fontFamily: 'Inter', color: '#75788D', fontSize: 14, letterSpacing: -0.5, padding: '1rem'}}>
            As a Graphic Designer, you will collaborate closely with cross-functional teams, including product managers and engineers, to understand user needs, define product requirements, and translate them into user-centric designs. You will also have the opportunity to build your portfolio  and  experience whilst working.
            </p>
          </div>
          <div className='popular-job'>
          <div style={{ display:'flex', flexWrap:'wrap', justContents: 'space-between', alignItems: 'center'}}>
              <img className='freelance-overview-img' src={'https://firebasestorage.googleapis.com/v0/b/advidity-facb4.appspot.com/o/woman-headshot-outdoors.jpeg?alt=media&token=f4e680f2-aecd-46ba-bb30-6593d2c1ffa8'} alt='Freelancer img'/>
              <div style={{width: '70%', padding: '0 2rem'}}>
                  <p style={{fontFamily: 'Satoshi', fontSize: 16, color: '#1e1e1e', letterSpacing: -1}}>Video Director</p>
                  <div style={{display: 'flex', flexDirection:'row', alignItems :'center'}}>
                    <p style={{fontFamily: 'Inter', fontSize: 14, color: '#75788D', letterSpacing: -1}}>Advidity</p>
                    <div style={{height: 6, width: 6, backgroundColor: '#1e1e1e', borderRadius: 3, margin: '0 1rem'}}/>
                    <div className='job-chip'>
                      <p>Full-time</p>
                    </div>
                    <div className='job-chip'>
                      <p>Remote</p>
                    </div>
                    <div className='job-chip'>
                      <p>£48,000</p>
                    </div>
                  </div>
              </div>

              <div>
                <p style={{fontFamily: 'Satoshi', fontSize: 14, color: '#1e1e1e', letterSpacing: -1}}>Birmingham, UK</p>
                <p style={{fontFamily: 'Inter', fontSize: 12, color: '#75788D', letterSpacing: -1}}>Posted 5 mins ago</p>

              </div>
            </div>
            <p style={{fontFamily: 'Inter', color: '#75788D', fontSize: 14, letterSpacing: -0.5, padding: '1rem'}}>
            As a Graphic Designer, you will collaborate closely with cross-functional teams, including product managers and engineers, to understand user needs, define product requirements, and translate them into user-centric designs. You will also have the opportunity to build your portfolio  and  experience whilst working.
            </p>
          </div>
          <div className='popular-job'>
          <div style={{ display:'flex', flexWrap:'wrap', justContents: 'space-between', alignItems: 'center'}}>
              <img className='freelance-overview-img' src={'https://firebasestorage.googleapis.com/v0/b/advidity-facb4.appspot.com/o/woman-headshot-outdoors.jpeg?alt=media&token=f4e680f2-aecd-46ba-bb30-6593d2c1ffa8'} alt='Freelancer img'/>
              <div style={{width: '70%', padding: '0 2rem'}}>
                  <p style={{fontFamily: 'Satoshi', fontSize: 16, color: '#1e1e1e', letterSpacing: -1}}>Video Director</p>
                  <div style={{display: 'flex', flexDirection:'row', alignItems :'center'}}>
                    <p style={{fontFamily: 'Inter', fontSize: 14, color: '#75788D', letterSpacing: -1}}>Advidity</p>
                    <div style={{height: 6, width: 6, backgroundColor: '#1e1e1e', borderRadius: 3, margin: '0 1rem'}}/>
                    <div className='job-chip'>
                      <p>Full-time</p>
                    </div>
                    <div className='job-chip'>
                      <p>Remote</p>
                    </div>
                    <div className='job-chip'>
                      <p>£48,000</p>
                    </div>
                  </div>
              </div>

              <div>
                <p style={{fontFamily: 'Satoshi', fontSize: 14, color: '#1e1e1e', letterSpacing: -1}}>Birmingham, UK</p>
                <p style={{fontFamily: 'Inter', fontSize: 12, color: '#75788D', letterSpacing: -1}}>Posted 5 mins ago</p>

              </div>
            </div>
            <p style={{fontFamily: 'Inter', color: '#75788D', fontSize: 14, letterSpacing: -0.5, padding: '1rem'}}>
            As a Graphic Designer, you will collaborate closely with cross-functional teams, including product managers and engineers, to understand user needs, define product requirements, and translate them into user-centric designs. You will also have the opportunity to build your portfolio  and  experience whilst working.
            </p>
          </div>
          <div className='popular-job'>
          <div style={{ display:'flex', flexWrap:'wrap', justContents: 'space-between', alignItems: 'center'}}>
              <img className='freelance-overview-img' src={'https://firebasestorage.googleapis.com/v0/b/advidity-facb4.appspot.com/o/woman-headshot-outdoors.jpeg?alt=media&token=f4e680f2-aecd-46ba-bb30-6593d2c1ffa8'} alt='Freelancer img'/>
              <div style={{width: '70%', padding: '0 2rem'}}>
                  <p style={{fontFamily: 'Satoshi', fontSize: 16, color: '#1e1e1e', letterSpacing: -1}}>Video Director</p>
                  <div style={{display: 'flex', flexDirection:'row', alignItems :'center'}}>
                    <p style={{fontFamily: 'Inter', fontSize: 14, color: '#75788D', letterSpacing: -1}}>Advidity</p>
                    <div style={{height: 6, width: 6, backgroundColor: '#1e1e1e', borderRadius: 3, margin: '0 1rem'}}/>
                    <div className='job-chip'>
                      <p>Full-time</p>
                    </div>
                    <div className='job-chip'>
                      <p>Remote</p>
                    </div>
                    <div className='job-chip'>
                      <p>£48,000</p>
                    </div>
                  </div>
              </div>

              <div>
                <p style={{fontFamily: 'Satoshi', fontSize: 14, color: '#1e1e1e', letterSpacing: -1}}>Birmingham, UK</p>
                <p style={{fontFamily: 'Inter', fontSize: 12, color: '#75788D', letterSpacing: -1}}>Posted 5 mins ago</p>

              </div>
            </div>
            <p style={{fontFamily: 'Inter', color: '#75788D', fontSize: 14, letterSpacing: -0.5, padding: '1rem'}}>
            As a Graphic Designer, you will collaborate closely with cross-functional teams, including product managers and engineers, to understand user needs, define product requirements, and translate them into user-centric designs. You will also have the opportunity to build your portfolio  and  experience whilst working.
            </p>
          </div>
        </div>
        }

    {tab === 4 && 
        <div id='popular-jobs-container'>
          <div className='popular-job'>
            <div style={{ display:'flex', flexWrap:'wrap', justContents: 'space-between', alignItems: 'center'}}>
              <img className='freelance-overview-img' src={'https://firebasestorage.googleapis.com/v0/b/advidity-facb4.appspot.com/o/woman-headshot-outdoors.jpeg?alt=media&token=f4e680f2-aecd-46ba-bb30-6593d2c1ffa8'} alt='Freelancer img'/>
              <div style={{width: '70%', padding: '0 2rem'}}>
                  <p style={{fontFamily: 'Satoshi', fontSize: 16, color: '#1e1e1e', letterSpacing: -1}}>Video Director</p>
                  <div style={{display: 'flex', flexDirection:'row', alignItems :'center'}}>
                    <p style={{fontFamily: 'Inter', fontSize: 14, color: '#75788D', letterSpacing: -1}}>Advidity</p>
                    <div style={{height: 6, width: 6, backgroundColor: '#1e1e1e', borderRadius: 3, margin: '0 1rem'}}/>
                    <div className='job-chip'>
                      <p>Full-time</p>
                    </div>
                    <div className='job-chip'>
                      <p>Remote</p>
                    </div>
                    <div className='job-chip'>
                      <p>£48,000</p>
                    </div>
                  </div>
              </div>

              <div>
                <p style={{fontFamily: 'Satoshi', fontSize: 14, color: '#1e1e1e', letterSpacing: -1}}>Birmingham, UK</p>
                <p style={{fontFamily: 'Inter', fontSize: 12, color: '#75788D', letterSpacing: -1}}>Posted 5 mins ago</p>

              </div>
            </div>
            <p style={{fontFamily: 'Inter', color: '#75788D', fontSize: 14, letterSpacing: -0.5, padding: '1rem'}}>
            As a Graphic Designer, you will collaborate closely with cross-functional teams, including product managers and engineers, to understand user needs, define product requirements, and translate them into user-centric designs. You will also have the opportunity to build your portfolio  and  experience whilst working.
            </p>
          </div>
          <div className='popular-job'>
          <div style={{ display:'flex', flexWrap:'wrap', justContents: 'space-between', alignItems: 'center'}}>
              <img className='freelance-overview-img' src={'https://firebasestorage.googleapis.com/v0/b/advidity-facb4.appspot.com/o/woman-headshot-outdoors.jpeg?alt=media&token=f4e680f2-aecd-46ba-bb30-6593d2c1ffa8'} alt='Freelancer img'/>
              <div style={{width: '70%', padding: '0 2rem'}}>
                  <p style={{fontFamily: 'Satoshi', fontSize: 16, color: '#1e1e1e', letterSpacing: -1}}>Video Director</p>
                  <div style={{display: 'flex', flexDirection:'row', alignItems :'center'}}>
                    <p style={{fontFamily: 'Inter', fontSize: 14, color: '#75788D', letterSpacing: -1}}>Advidity</p>
                    <div style={{height: 6, width: 6, backgroundColor: '#1e1e1e', borderRadius: 3, margin: '0 1rem'}}/>
                    <div className='job-chip'>
                      <p>Full-time</p>
                    </div>
                    <div className='job-chip'>
                      <p>Remote</p>
                    </div>
                    <div className='job-chip'>
                      <p>£48,000</p>
                    </div>
                  </div>
              </div>

              <div>
                <p style={{fontFamily: 'Satoshi', fontSize: 14, color: '#1e1e1e', letterSpacing: -1}}>Birmingham, UK</p>
                <p style={{fontFamily: 'Inter', fontSize: 12, color: '#75788D', letterSpacing: -1}}>Posted 5 mins ago</p>

              </div>
            </div>
            <p style={{fontFamily: 'Inter', color: '#75788D', fontSize: 14, letterSpacing: -0.5, padding: '1rem'}}>
            As a Graphic Designer, you will collaborate closely with cross-functional teams, including product managers and engineers, to understand user needs, define product requirements, and translate them into user-centric designs. You will also have the opportunity to build your portfolio  and  experience whilst working.
            </p>
          </div>
          <Link to='jobs' onClick={() => setActive('Jobs')}>
            <button id='see-jobs-btn'>
                See all job posts
            </button>
          </Link>
          
         
        </div>
        }

      </div>
      
      <NotificationAndMessages setActive={setActive}/>

    </div>
  )
}
