import React, { useState } from 'react';

export default function ContestFAQ({title, content}) {

    const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };


  return (

    <div className='faq' onClick={toggleAccordion}>
        <div style={{display: 'flex', justifyContent: 'space-between', alignItems:'center', width: '100%'}}>
            <p style={{fontSize: 20}}>{title}</p>
            <div className='check-circle'>
            <p className={isOpen ? 'rotated-cross' : null}>+</p>
            </div>
        </div>
        {isOpen && (
        <div className="faq-content">
          <p className='advidity-subheader' style={{fontSize: 18}}>{content}</p>
        </div>
      )}
    </div>
  )
}
