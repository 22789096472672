import React from 'react'
import NotificationAndMessages from './NotificationAndMessages'
import { Link } from 'react-router-dom'

export default function Points() {
  return (
    <div className='business-outlet'>
       <div id='business-dash-content'>
            <p className='advidity-header'>Points</p>
            <p className='advidity-subheader'>Gain points to build your credibility to Businesses</p>

            <div className='create-contest-box'>
              <p style={{fontFamily:'Satoshi', fontSize: 24, letterSpacing: -0.5, color: '#fff'}}>Set up your wallet to start getting paid for every job you complete.</p>
              <p style={{fontFamily:'Inter', fontSize: 16, letterSpacing: -0.5, color: '#fff', margin: '1rem 0', maxWidth: 600, lineHeight: 1.6}}>Get paid for jobs and earn points at the same time</p>
              
          </div>

          <div style={{width: '100%', minHeight: 400, display: 'flex', flexWrap: 'wrap'}}>

          <div className='dash-contests' style={{width: '49%', marginRight: '2%'}}>
                <p className='tab-content-header'>Total Points</p>
                <p className='tab-content-title'>0</p>
               
            </div>

            <div className='dash-contests' style={{width: '49%'}}>
                <p className='tab-content-header'>Referral points</p>
                <p className='tab-content-title'>0</p>
                <Link to='points'  className='tab-content-btn'>
                    <button className='tab-content-btn'>Get referral link</button>
                </Link>
            </div>

            <div className='dash-contests' style={{width: '49%', marginRight: '2%'}}>
                <p className='tab-content-header'>Course Points</p>
                <p className='tab-content-title'>0</p>
                <Link to='/freelance/learning-portal'  className='tab-content-btn'>
                    <button className='tab-content-btn'>Take a course</button>
                </Link>
            </div>

            <div className='dash-contests' style={{width: '49%'}}>
                <p className='tab-content-header'>Contest Points</p>
                <p className='tab-content-title'>0</p>
                <Link to='/freelance/contests'  className='tab-content-btn'>
                    <button className='tab-content-btn'>View contests</button>
                </Link>
            </div>
            
          </div>
        </div>

        <NotificationAndMessages/>
        

    </div>
  )
}
