import React from 'react';
import { Navigate, useLocation, Outlet } from 'react-router-dom';
import { useAuth } from '../../Context/AuthContext';

const RequireBusinessAuth = ({userRole}) => {

    const {currentUser, role} = useAuth()
    let location = useLocation();

    return currentUser && role === userRole ? <Outlet /> : <Navigate to="/login" state={{from : location}} replace/> 

};

export default RequireBusinessAuth;