import React, { useState, useEffect} from 'react'
import NotificationAndMessages from './NotificationAndMessages'
import { useParams, useNavigate } from 'react-router-dom'
import axios from 'axios'
import moment from 'moment'
import JobsApply from './JobApply'
import { useAuth } from '../../Context/AuthContext'

export default function JobDetails() {

    let { id } = useParams()
    let { currentUser } = useAuth()
    let navigate = useNavigate()

    const [job, setJob] = useState("")
    const [openApply, setOpenApply] = useState(false)
    const [hasApplied, setHasApplied] = useState(false)

    useEffect(() => {
        getjobInfo()
        checkIfApplied()
    }, [])

    const getjobInfo = () => {
        axios.post(`${process.env.REACT_APP_API_URL}/jobs/get-job-by-id`, {jobId : id})
        .then((res) => {
            setJob(res.data)
        })
        .catch((e) => {
            console.log(e)
        })
    }

    const checkIfApplied = () => {
        axios.post(`${process.env.REACT_APP_API_URL}/jobs/check-if-applied`, {applicantId: currentUser.uid, jobId: id})
        .then((res) => {
            setHasApplied(res.data)
        })
        .catch((e) => {
            console.log(e)
        })
    }

  return (
    <div className='business-outlet'>
        <div id='business-dash-content' >
            <p className='advidity-header'>Jobs</p>
            <p className='advidity-subheader'>Find your preferred jobs, contests, and many more</p>
            <div style={{width: '100%', minHeight: 300, display: 'flex', flexWrap: 'wrap'}}>
                
                {job &&
                <div className='job-description'>
                    <div className='advidity-row' style={{justifyContent: 'space-between'}}>
                        <p className='advidity-header' style={{fontSize: 22}}>{job.title}</p>
                        <button className='primary-btn' style={hasApplied ? {backgroundColor: 'green'} : null} onClick={() => setOpenApply(true)} disabled={hasApplied}>{hasApplied ? 'Applied' : 'Apply Now'}</button>
                    </div>

                    <div className='advidity-row' style={{marginBottom: '2rem'}}>
                        <div className='job-chip'>
                            <p>{job.secondarySkill}</p>
                        </div>
                        <div className='job-chip'>
                            <p>£{job.budget}</p>
                        </div>
                        <div className='job-chip'>
                            <p>{moment(job.deadline).format('Do MMM YYYY')}</p>
                        </div>
                    </div>

                    <label>Job Description</label>
                    <textarea className='job-textarea advidity-subheader' defaultValue={job.description} />

                    <label>Deliverables</label>
                    <textarea className='job-textarea advidity-subheader' defaultValue={job.deliverables} style={{marginBottom: '2rem'}} />

                    <label>Additional Notes</label>
                    <textarea className='job-textarea advidity-subheader' defaultValue={job.notes} />

                </div>
                }

                <button className='secondary-btn' onClick={() => navigate(-1)}>Back</button>
            </div>
        </div>

        <NotificationAndMessages />
        {openApply && <JobsApply setOpenApply={setOpenApply} job={job} checkIfApplied={checkIfApplied}/>}

    </div>
  )
}
