import React, {useState, useEffect} from 'react'
import axios from 'axios'
import { useAuth } from '../../../Context/AuthContext'
import ContestApplicantCard from './ContestApplicantCard'
import { useParams } from 'react-router-dom'
import ContestPayment from './ContestPayment'

export default function ContestApplicants() {

  let { id } = useParams()

  const [tab, setTab] = useState(1)
  const [received, setReceived] = useState([])
  const [shortlisted, setShortlisted] = useState([])
  const [rejected, setRejected] = useState([])

  useEffect(() => {
    getContestApplications()
  }, [])


  const getContestApplications = () => {
    axios.post(`${process.env.REACT_APP_API_URL}/contests/get-applications-by-contest`, { id })
    .then((res) => {
     
        let received = res.data.filter((application) => (application.stage === 'Received'))
        let shortlisted = res.data.filter((application) => (application.stage === 'Shortlisted'))
        let rejected = res.data.filter((application) => (application.stage === 'Rejected'))
        setReceived(received)
        setShortlisted(shortlisted)
        setRejected(rejected)
    })
    .catch((e) => {
      console.log(e)
    })
  }


  return (
    <div className='business-outlet'>
       <div style={{padding: '2rem'}}>
        <p className='advidity-header'>Contest Applicants</p>
        <div className='advidity-row' style={{justifyContent:'space-between'}}>
          <p className='advidity-subheader'>Track list of talents that have applied to your contests</p>
        </div>

        <div className='tab-container' style={{width: 600, overflow: 'scroll'}}>
                <div className={tab === 1 ? 'tab-active' : 'tab'} onClick={() => setTab(1)}>
                  <p>Applications Received</p>
                  <div className='tab-square'>
                    <p>{received.length}</p>
                  </div>
                </div>

                <div className={tab === 2 ? 'tab-active' : 'tab'} onClick={() => setTab(2)}>
                  <p>Shortlisted</p>
                  <div className='tab-square'>
                    <p>{shortlisted.length}</p>
                  </div>
                </div>

                <div className={tab === 3 ? 'tab-active' : 'tab'} onClick={() => setTab(3)}>
                  <p>Rejected</p>
                  <div className='tab-square'>
                    <p>{rejected.length}</p>
                  </div>
                </div>
        </div>

        <div style={{width: '100%', minHeight: 300, display: 'flex', flexWrap: 'wrap'}}>
            

            {tab === 1 &&
              received.map((application, i) => {
                return <ContestApplicantCard application={application} getContestApplications={getContestApplications} contestId={id} key={i}/>
              })
            }
             {tab === 2 &&
              shortlisted.map((application, i) => {
                return <ContestApplicantCard application={application} getContestApplications={getContestApplications} contestId={id} key={i}/>
              })
            }
           
           {tab === 3 &&
              rejected.map((application, i) => {
                return <ContestApplicantCard application={application} getContestApplications={getContestApplications} contestId={id} key={i}/>
              })
            }
            
        </div>


      </div>
    </div>
  )
}
