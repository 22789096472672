import './Main.css';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useState } from 'react';
import logo from '../../Assets/logo.png'
import { Link } from 'react-router-dom'

function Main() {

  const url = process.env.REACT_APP_MAILCHIMP_URL;

  const [email, setEmail] = useState("")
  const [name, setName] = useState("")
  const [account, setAccount] = useState("")
  const [saving, setSaving] = useState(false)

  const register = (e) => {
    e.preventDefault()

    if(!name || !email || ! account){
      toast.error("Please add all fields")
    } else {
      setSaving(true)
      axios.post(`${process.env.REACT_APP_API_URL}/register-interest`, {name, email, account})
      .then((res) => {
        toast.success(res.data)
        setName("");setEmail("");setAccount("")
        setSaving(false)

      })
      .catch((e) => {
        toast.error("Error signing up")
        setSaving(false)
      })
    }
    
  }

  return (
    <div className="App">
                  
                  <div className="page" id="p1">
                  <div id='page-overlay'/>
                      <Link id='comp-btn' to={'/competition'}>
                        <h4 >Enter Competition</h4>
                        <img src='https://icongr.am/octicons/arrow-right.svg?size=28&color=ffffff' alt='arrow'/>
                      </Link>

                      <img src={logo} id='landing-logo'/> 

                      <p style={{color: '#fff', marginTop: 30,marginBottom: 50, maxWidth: '80vw', width: 600, textAlign:'center'}}>Stay ahead of the curve and be the first to know about our launch!<br/><br/> Coming to the app store soon - Register your interest below and become part of the Advidity revolution. </p>

                      <form id='landing-form'>
                        <h2>Register interest</h2>
                        <label>I am a </label>
                        <select value={account} onChange={(e) => setAccount(e.target.value)}>
                          <option value="" disabled>Select</option>
                          <option value="Freelancer" >Freelancer</option>
                          <option value="Business" >Business</option>
                        </select>

                        <input placeholder='Full Name' value={name} onChange={(e) => setName(e.target.value)}/>
                        <input placeholder='Email' value={email} onChange={(e) => setEmail(e.target.value)}/>

                        <button onClick={register} disabled={saving}>{saving? 'Saving...' : 'Submit'}</button>

                      </form>
                
       
        </div>
    </div>
  );
}

export default Main;
