import React, { useState, useEffect} from 'react'
import NotificationAndMessages from './NotificationAndMessages'
import { useParams, useNavigate } from 'react-router-dom'
import axios from 'axios'
import moment from 'moment'
import { useAuth } from '../../Context/AuthContext'
import ContestApply from './ContestsApply'

export default function ContestDetails() {

    let { id } = useParams()
    let { currentUser } = useAuth()
    let navigate = useNavigate()

    const [contest, setContest] = useState("")
    const [openApply, setOpenApply] = useState(false)
    const [hasApplied, setHasApplied] = useState(false)

    useEffect(() => {
        getContestInfo()
        checkIfApplied()
    }, [])

    const getContestInfo = () => {
        axios.post(`${process.env.REACT_APP_API_URL}/contests/get-contest-by-id`, {contestId : id})
        .then((res) => {
            setContest(res.data)
        })
        .catch((e) => {
            console.log(e)
        })
    }

    const checkIfApplied = () => {
        axios.post(`${process.env.REACT_APP_API_URL}/contests/check-if-applied`, {applicantId: currentUser.uid, contestId: id})
        .then((res) => {
            setHasApplied(res.data)
        })
        .catch((e) => {
            console.log(e)
        })
    }

  return (
    <div className='business-outlet'>
        <div id='business-dash-content' >
            <p className='advidity-header'>One-off Contests</p>
            <p className='advidity-subheader'>Find your preferred jobs, contests, and many more</p>
            <div style={{width: '100%', minHeight: 300, display: 'flex', flexWrap: 'wrap'}}>
                
                {contest &&
                <div className='job-description'>
                    <div className='advidity-row' style={{justifyContent: 'space-between'}}>
                        <p className='advidity-header' style={{fontSize: 22}}>{contest.title}</p>
                        <button className='primary-btn' style={hasApplied ? {backgroundColor: 'green'} : null} onClick={() => setOpenApply(true)} disabled={hasApplied}>{hasApplied ? 'Applied' : 'Apply Now'}</button>
                    </div>

                    <div className='advidity-row' style={{marginBottom: '2rem'}}>
                        <div className='job-chip'>
                            <p>{contest.secondarySkill}</p>
                        </div>
                        <div className='job-chip'>
                            <p>£{contest.prizeMoney}</p>
                        </div>
                        <div className='job-chip'>
                            <p>{moment(contest.deadline).format('Do MMM YYYY')}</p>
                        </div>
                    </div>

                    <label>Contest Description</label>
                    <textarea className='job-textarea advidity-subheader' defaultValue={contest.description} />

                    <label>Deliverables</label>
                    <textarea className='job-textarea advidity-subheader' defaultValue={contest.deliverables} style={{marginBottom: '2rem'}} />

                    <label>Additional Notes</label>
                    <textarea className='job-textarea advidity-subheader' defaultValue={contest.notes} />

                </div>
                }

                <button className='secondary-btn' onClick={() => navigate(-1)}>Back</button>
            </div>
        </div>

        <NotificationAndMessages />
        {openApply && <ContestApply setOpenApply={setOpenApply} contest={contest} checkIfApplied={checkIfApplied}/>}

    </div>
  )
}
