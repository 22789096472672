import React, { useEffect, useState} from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import axios from 'axios'
import DotLoader from "react-spinners/DotLoader";
import './CourseOverview.css'
import { useAuth } from '../../../Context/AuthContext'

const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };

export default function CourseOverview() {

    let { id } = useParams()
    const navigate = useNavigate();
    const { currentUser } = useAuth()

    const [course, setCourse] = useState("")
    const [loading, setLoading] = useState(true)
    const [hasCompleted, setHasCompleted] = useState(false)
    const [hasStarted, setHasStarted] = useState(false)
    const [startedCourseId, setStartedCourseId] = useState("")

 
    useEffect(() => {
        hasStartedCourse()
    }, [])

    const getCourseInfo = () => {
        
        axios.post(`${process.env.REACT_APP_API_URL}/course/get-course-by-id`, {_id: id})
        .then((res) => {
            console.log(res.data)
            setCourse(res.data)
            setLoading(false)
        })
        .catch((e) => {
            console.log(e)
            setLoading(false)
        })
    }

    const startCourse = () => {
        setLoading(true)
        axios.post(`${process.env.REACT_APP_API_URL}/course/start-course`, 
        {
            courseId: id, 
            userId: currentUser.uid, 
            courseTitle: course.title, 
            points: course.points,
            questions: course.questions,
            completed: false
        })
        .then((res) => {
            setLoading(false)
            console.log(res.data)
            navigate(`/freelance/learning-portal/my-courses/${res.data._id}`)

        })
        .catch((e) => {
            console.log(e)
            setLoading(false)

        })
    }

    const hasStartedCourse = () => {
        axios.post(`${process.env.REACT_APP_API_URL}/course/get-started-course`, {courseId: id, userId: currentUser.uid})
        .then((res) => {
            console.log(res.data)
            if(res.data.length > 0){
                //course already started
                setHasStarted(true)
                setHasCompleted(res.data[0].completed)
                setStartedCourseId(res.data[0]._id)
            } else {
                setHasStarted(false)
            }
            getCourseInfo()
        })
        .catch((e) => {
            console.log(e)
            setLoading(false)
        })
    }

  return (
    <div className='business-outlet'>

        {course ? 
        <div style={{padding: '2rem'}}>
             <p className='advidity-header'>Courses</p>
            <p className='advidity-subheader'>Complete courses to gain skills and points</p>

            <div className='create-contest-box'>
              <p style={{fontFamily:'Satoshi', fontSize: 24, letterSpacing: -0.5, color: '#272838'}}>{course.title}</p>
              <p style={{fontFamily:'Inter', fontSize: 16, letterSpacing: -0.5, color: 'grey', margin: '1rem 0', maxWidth: 600, lineHeight: 1.6}}>{course.description}</p>
          </div>

           

            <div>
                {!hasStarted && <button id='start-btn' onClick={startCourse}>Start now</button>}
                {hasStarted && hasCompleted && <p style={{color:'green'}}>Course Completed</p>}
                {hasStarted && !hasCompleted && <button id='start-btn' onClick={() => navigate(`/freelance/learning-portal/my-courses/${startedCourseId}`) }>Continue</button>}

                <button id='back-btn' onClick={() => navigate(-1)}>Back</button>
            </div>

            
        </div>
        :
        <DotLoader
        color={'grey'}
        loading={loading}
        cssOverride={override}
        size={100}
        aria-label="Loading Spinner"
        data-testid="loader"
        loader="DotLoader"
      />
}
        
    </div>
  )
}
