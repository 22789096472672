import React, { useState, useEffect} from 'react'
import cvIcon from '../../Assets/icons/cv-icon.png'
import { useAuth} from '../../Context/AuthContext'
import axios from 'axios'
import { toast } from 'react-toastify'
import congrats from '../../Assets/icons/congrats.png'
//Firebase
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from '../../Firebase';

export default function ContestApply({setOpenApply, contest, checkIfApplied}) {

  const [step, setStep] = useState(1)
  const [saving, setSaving] = useState(false)

  let { currentUser} = useAuth()

  const [name, setName] = useState(currentUser.displayName)
  const [email, setEmail] = useState(currentUser.email)
  const [fileName, setFileName] = useState("Click to upload file")
  const [file, setFile] = useState("")
  const [link, setLink] = useState("")
  const [notes, setNotes] = useState("")

  const handleSubmit = () => {
    let payload = {
      contestId: contest._id,
      contestTitle: contest.title,
      businessId: contest.businessId,
      businessName: contest.businessName,
      applicantId: currentUser.uid,
      name, 
      email,
      link,
      file,
      notes,
      status: "Pending",
      stage: "Received"
     
    }

    if( !name || !email || (!file && !link)) {
      toast.error("Please add all necessary fields")
    } else {
      setSaving(true)
      axios.post(`${process.env.REACT_APP_API_URL}/contests/apply-for-contest`, payload)
      .then(() => {
        checkIfApplied()
        setStep(2)
        setSaving(false)
      })
      .catch((e) => {
        console.log(e)
        setSaving(false)
      })
    }
    
  }
  const fileRef = ref(storage, `freelancer/contests/${contest._id}/${currentUser.uid}`);

  const uploadFile = (file) => {
    if(file?.size > 2097152){
        toast.error("File must be less than 2mb")
      } else if( !file){
        return
      } else {
        setFileName("Uploading...")
        uploadBytes(fileRef, file).then((snapshot) => {
          getDownloadURL(fileRef)
          .then((url) => {
            setFileName(file.name)
            setFile(url)
          })
          .catch((error) => {
              console.log(error)
          });
        });
      }
  }

  return (
    <div id='create-contest'>
        <div id='create-contest-container' style={{paddingTop: '1rem', overflow:'scroll'}}>
            <button onClick={() => setOpenApply(false)} id='close-contest-btn'>x</button>

            {step === 1 &&
            <div style={{padding: '0 1rem'}}>
                <p className='advidity-header' style={{fontSize: 28, marginBottom: 20}}>Apply</p>

                <input className='apply-input' type='text' placeholder='Enter Full Name' value={name} onChange={(e) => setName(e.target.value)}/>

                <input className='apply-input' type='email' placeholder='Enter Email'  value={email} onChange={(e) => setEmail(e.target.value)}/>

                <input className='apply-input' type='text' placeholder='Link to your Submission'  value={link} onChange={(e) => setLink(e.target.value)}/>

                <div className='advidity-row' style={{width: '100%', justifyContent:'space-evenly', alignItems:'center'}}>
                    <div className='line-divider'/>
                    <p>or</p>
                    <div className='line-divider'/>
                </div>

                <p className='advidity-header' style={{fontSize: 20, margin: '10px 0'}}>Upload File</p>

                <input type="file"  name="pic1" id="pic1" style={{display:'none'}} onChange={(e)=>uploadFile(e.target.files[0])}/>
                <label htmlFor="pic1">
                <div className='apply-input' style={{display:'flex', justifyContent:'center', alignItems:'center', padding: '3rem 1rem'}}>
                        <img style={{height: 50, width: 50, marginRight: 20}} src={cvIcon} alt='CV icon'/>
                        <p className='advidity-subheader'>{fileName}</p>
                    </div>
                </label>

                <textarea className='apply-input' style={{resize: 'none', height: 200, padding: 10}} placeholder='Notes or Comments' value={notes} onChange={(e) => setNotes(e.target.value)}/>

                <button className='primary-btn'  onClick={handleSubmit} disabled={saving}>{saving ? "Applying..." : "Submit Application"}</button>

                <p style={{fontSize: 14}} className='advidity-subheader'>Please note, by submitting this content you are agreeing to our <a href='https://sandbox.advidity.app/freelancer-contest-terms' target='_blank' style={{color:'#EB9486', fontSize: 16}}>Terms & Conditions</a></p>

              </div>
            } 

            {step === 2 &&
            <div>

                <div style={{backgroundColor:'#ebebeb', margin: '75px 2rem', borderRadius: 8, textAlign:'center', padding: 10}}>
                    <img  src={congrats} alt='Congratulations image'/>
                </div>
                <p className='advidity-header' style={{fontSize: 28, margin: '2rem'}}>Congratulations, you have successfully submitted your application for this contest.</p>

                <button className='primary-btn' style={{margin: '2rem', color: 'white'}} onClick={() => { setOpenApply(false)}}>Close</button>

            </div>
            }

        
          
        </div>
    </div>
  )
}
