import React, { useState, useEffect } from 'react'
import './Messages.css'
import search from '../../Assets/icons/search.png'
import moment from 'moment'
import { Outlet, useNavigate } from 'react-router-dom'
import { useAuth } from '../../Context/AuthContext'
import axios from 'axios'

export default function Messages() {

 let { currentUser } = useAuth()
 let navigate = useNavigate()

  const [chats, setChats] = useState([])

  useEffect(() => {
    getChats()
  }, [])

  const getChats = () => {
    axios.post(`${process.env.REACT_APP_API_URL}/chats/get-business-chats-by-id`, {id: currentUser.uid})
      .then((res) => {
        setChats(res.data)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const [tab, setTab] = useState(1)
  return (
    <div className='business-outlet'>
      <div id='message-contents'>

        <div className='advidity-row' style={{justifyContent: 'space-between'}}>
          <p className='advidity-header'>Messaging</p>
          <div className='advidity-row'>
            <div className='advidity-input' style={{paddingLeft: 'unset', width: 250, marginRight: 10, position: 'relative'}}>
              <img src={search} alt='Search icon' style={{height: 25, position: 'absolute', left: 10, top: 10}}/>
              <input style={{border: 'unset', background: 'unset', height: '100%', width: '100%', paddingLeft: 50, fontSize: 16}}  placeholder='Search messages' />
            </div>
            <button className='primary-btn'>Search</button>
          </div>
        </div>

        <div className='advidity-row'>
            <div id='messages-left-container'>
              <div className='tab-container' style={{justifyContent: 'left', margin: 0, borderRadius: 0}}>
                <div className={tab === 1 ? 'tab-active' : 'tab'} onClick={() => setTab(1)}>
                  <p>Inbox</p>
                  <div className='tab-square'>
                    {chats.length}
                  </div>
                </div>


              </div>

              {chats.length < 1 ? <p style={{textAlign:'center', color: 'grey', marginTop: 100}}>Your inbox is empty</p> : chats.map((msg, i) => {
                return (
                  <div key={i} className='msg-snapshot' onClick={() => navigate(msg._id)}>
                    <div className='msg-avatar'>
                        <p>{msg.freelancerName.split(" ")[0].charAt(0)}{msg.freelancerName.split(" ")[1].charAt(0)}</p>
                    </div>

                    <div style={{flexDirection: 'column', width: '80%', height: '100%', overflow: 'hidden'}}>
                      <div style={{display: 'flex', justifyContent: 'space-between', marginBottadvidity: 5, color: 'grey', fontSize: 14}}>
                        <p style={{textTransform: 'uppercase'}}>{msg.freelancerName}</p>
                        <p>{moment(msg.lastMsgAdded).fromNow()}</p>
                      </div>

                      <p>{msg.lastMsg}</p>

                    </div>

                  </div>
                )
              })
            }
            

            </div>
            <div id='messages-right-container'>
              <Outlet context={{getChats}}/>
            </div>
           
        </div>

       

      </div>
    </div>
  )
}
