import React, { useEffect, useState} from 'react'
import axios from 'axios'
import courseImg from '../../../Assets/course.png'
import './LearningPortal.css'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../../Context/AuthContext'
import NotificationAndMessages from '../NotificationAndMessages'
import computer from '../../../Assets/course-img.png'

export default function LearningDashboard() {

    const [courses, setCourses] = useState([])
    const [freelancerCourses, setFreelancerCourses] = useState([])

    let { currentUser } = useAuth()

    let navigate = useNavigate()

    useEffect(() => {
        getCourses()
        getFreelancerCourses()
    }, [])

    const getCourses = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/course/get-published-courses`)
        .then((res) => {
            setCourses(res.data)
        })
        .catch((e) => {
            console.log(e)
        })
    }

    const getFreelancerCourses = () => {
        axios.post(`${process.env.REACT_APP_API_URL}/course/get-freelancer-courses`, {_id: currentUser.uid})
        .then((res) => {
            setFreelancerCourses(res.data)
        })
        .catch((e) => {
            console.log(e)
        })
    }

  return (
    <div className='business-outlet' style={{flexDirection:'column', padding:'1rem'}} >
        <div id='business-dash-content'>
            <p className='advidity-header'>Courses</p>
            <p className='advidity-subheader'>Complete courses to gain skills and points</p>

            <div className='create-contest-box'>
              <p style={{fontFamily:'Satoshi', fontSize: 24, letterSpacing: -0.5, color: '#fff'}}>Become a highly sort-after freelancer</p>
              <p style={{fontFamily:'Inter', fontSize: 16, letterSpacing: -0.5, color: '#fff', margin: '1rem 0', maxWidth: 600, lineHeight: 1.6}}>Seize the opportunity to take part in our courses and improve your skillset and gain points that ranks you high to employers</p>
              <img style={{height: "90%", position: 'absolute', right: '-1%', bottom: '-5%'}} src={computer} alt='"Courses computer'/>
          </div>

          <div style={{width: '100%', minHeight: 300, display: 'flex', flexWrap: 'wrap'}}>
              {courses.length < 1 ?
              <p>No courses added</p>
              :
              courses.map((course, i) => {
                  return (
                        <div key={i} className='course-container' onClick={() => navigate(`${course._id}`)}>
                            <img style={{width: '80%'}} src={courseImg} alt="course image"/>

                            <div style={{display: 'flex', justifyContent:'space-between', alignItems: 'center', margin: '10px 0'}}>
                                <p>{course.title}</p>
                                <div className='course-price'><p>Free</p></div>
                            </div>
                        </div>
                  )
              })
              }

          </div>
        </div>

        <NotificationAndMessages />
        
    </div>
  )
}
