import { createContext, useContext, useEffect, useState } from 'react'
import axios from 'axios'
import { useAuth } from './AuthContext';

const UserContext = createContext();

export function useUser() {
    return useContext(UserContext)
}

export function UserProvider( {children} ) {

    const [userInfo, setUserInfo] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    const { currentUser, role } = useAuth()
   
    useEffect(() => { 
        if(currentUser){
             getUserInfo()
        }
    }, [currentUser]);

    const getUserInfo = () => {
        if(role === "Business"){
            axios.post(`${process.env.REACT_APP_API_URL}/business/get-account-info`, {_id: currentUser.uid})
            .then((res) => {
                setUserInfo(res.data)
                setIsLoading(false)
            })
            .catch((e) => {
                console.log(e)
                setIsLoading(false)
            })

        } else if(role === "Freelancer"){
            axios.post(`${process.env.REACT_APP_API_URL}/freelance/get-user-info`, {_id: currentUser.uid})
            .then((res) => {
                setUserInfo(res.data)
                setIsLoading(false)
            })
            .catch((e) => {
                console.log(e)
                setIsLoading(false)
            })
        }
       
    }

    const value = {
        userInfo, getUserInfo
    }

    return (
        <UserContext.Provider value={value}>
            {!isLoading && children}
        </UserContext.Provider>
    )
}
