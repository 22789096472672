import React, { useState } from 'react'
import { useAuth } from '../../Context/AuthContext'
import PaymentForm from './PaymentForms'
import stripePromise from '../../Stripe'
import {  Elements} from '@stripe/react-stripe-js';

export default function Wallet() {

  const { currentUser } = useAuth()

  const [amount, setAmount] = useState("")



  return (
    <div className='business-outlet'>
       <div id='business-dash-content'>
            <p className='advidity-header'>Wallet</p>
            <p className='advidity-subheader'>Track the inflow and outflow of funds</p>

            <input value={amount} onChange={(e) => setAmount(e.target.value)}/>
            <Elements stripe={stripePromise}>

              <PaymentForm 
                userId={currentUser.uid}
                name={currentUser.displayName}
                email={currentUser.email}
                amount={amount}
                connectedAccountId={"acct_1OeaPSGbz8opq9g3"}
              />
            </Elements>

            
        </div>

    </div>
  )
}
