import './App.css';
import Login from './Components/Auth/Login';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import CreateAccount from './Components/Auth/CreateAccount';
import Main from './Components/Landing/Main';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RequireAuth from './Components/Auth/RequireAuth';
import BusinessUI from './Components/Business/BusinessUI';
import Profile from './Components/Freelancer/Profile/Profile';
import LearningDashboard from './Components/Freelancer/LearningPortal/LearningDashboard';
import CourseOverview from './Components/Freelancer/LearningPortal/CourseOverview';
//Business components
import Applicants from './Components/Business/Applicants';
import BusinessDashboard from './Components/Business/BusinessDashboard';
import BusinessMessages from './Components/Business/Messages';
import Contests from './Components/Business/Contests/Contests';
import Jobs from './Components/Business/Jobs/Jobs';
import Wallet from './Components/Business/Wallet';
import Chat from './Components/Business/Chat'
//Freelance components
import Applications from './Components/Freelancer/Applications';
import Dashboard from './Components/Freelancer/Dashboard';
import FreelanceUI from './Components/Freelancer/FreelanceUI';
import Messages from './Components/Freelancer/Messages';
import FreelanceChat from './Components/Freelancer/Chat'
import FreelancerContests from './Components/Freelancer/Contests';
import FreelancerJobs from './Components/Freelancer/Jobs';
import FreelancerWallet from './Components/Freelancer/Wallet';
import Points from './Components/Freelancer/Points';

//Context
import { UserProvider } from './Context/UserContext';
import { AuthProvider } from './Context/AuthContext';
import CompleteCourse from './Components/Freelancer/LearningPortal/CompleteCourse';
import Competition from './Components/Landing/Competition';
import Success from './Components/Landing/Success';
import CreateBusinessAccountContinued from './Components/Auth/CreateBusinessAccountContinued';
import CreateFreelancerAccountContinued from './Components/Auth/CreateFreelancerAccountContinued';
import Settings from './Components/Freelancer/Settings';
import JobDetails from './Components/Freelancer/JobDetails';
import ContestDetails from './Components/Freelancer/ContestDetails';
import ContestApplicants from './Components/Business/Contests/ContestApplicants';
import Portfolio from './Components/Freelancer/Profile/Portfolio';
import FreelancerTerms from './Components/Public/FreelancerTerms';
import BusinessTerms from './Components/Public/BusinessTerms';

function App() {
  return (
    <AuthProvider>                    {/*Wrapper for Firebase*/}
    <UserProvider>
    <BrowserRouter>
    <Routes>
      
      {/*Public routes */}
      <Route path='/login' element={<Login />} />
      <Route path='/create' element={<CreateAccount />} />
      <Route path='/create-business-account' element={<CreateBusinessAccountContinued />} />
      <Route path='/create-freelancer-account' element={<CreateFreelancerAccountContinued />} />
      <Route path='/' element={<Main />} />
      <Route path='/competition' element={<Competition />} />
      <Route path='/success' element={<Success />} />
      <Route path='/freelancer-contest-terms' element={<FreelancerTerms />} />
      <Route path='/business-contest-terms' element={<BusinessTerms />} />

      {/*Freelance routes */}
      <Route element={<RequireAuth userRole="Freelancer" />} >
        <Route path='/freelance' element={<FreelanceUI />} >
          <Route index element={<Dashboard />}/>
          <Route path='jobs' element={<FreelancerJobs />} />
          <Route path='jobs/:id' element={<JobDetails />} />
          <Route path='contests' element={<FreelancerContests />} />
          <Route path='contests/:id' element={<ContestDetails />} />
          <Route path='messages' element={<Messages />} >
            <Route path=':id' element={<FreelanceChat />} />
          </Route>          
          <Route path='wallet' element={<FreelancerWallet />} />
          <Route path='portfolio' element={<Portfolio />} />
          <Route path='points' element={<Points />} />
          <Route path='learning-portal' element={<LearningDashboard />} />
          <Route path='learning-portal/:id' element={<CourseOverview />} />
          <Route path='learning-portal/my-courses/:id' element={<CompleteCourse />} />
          <Route path='settings' element={<Settings />} />
        </Route>
      </Route>

      {/*Business routes */}
      <Route element={<RequireAuth  userRole="Business"/>} >
        <Route path='/business' element={<BusinessUI />} >
          <Route index element={<BusinessDashboard />}/>
          <Route path='contests' element={<Contests />} />
          <Route path='contests/:id' element={<ContestApplicants />} />
          <Route path='jobs' element={<Jobs />} />
          <Route path='applicants' element={<Applicants />} />
          <Route path='messages' element={<BusinessMessages />} >
            <Route path=':id' element={<Chat />} />
          </Route>
          <Route path='wallet' element={<Wallet />} />
        </Route>
      </Route>

    </Routes>
    
  <ToastContainer position='top-center'/>

  </BrowserRouter>  
  </UserProvider>
  </AuthProvider>    
  );
}

export default App;
