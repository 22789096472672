import React from 'react'
import './Terms.css'

export default function BusinessTerms() {
  return (
    <div className='terms'>
        <h1 style={{fontFamily: 'Satoshi'}}>Advidity Contest Application Terms and Conditions</h1>

        <p>By creating a contest on advidity, you agree to the following terms and conditions:</p>

 

    <p className='term-header'>Contest Guidelines:</p>

   <p>You agree to create contests that are lawful, respectful, and in compliance with advidity's community guidelines.</p>

 

   <p className='term-header'>Ownership of Content:</p>

   <p>You retain ownership of any content you submit or create for the contest. However, by submitting content, you grant advidity a non-exclusive, worldwide, royalty-free licence to use, reproduce, and display the content for promotional purposes related to the contest and advidity's services.</p>

 

   <p className='term-header'>Eligibility:</p>

   <p>You confirm that you have the authority and legal capacity to create contests on advidity. Contests created must not infringe upon the rights of third parties.</p>

 

   <p className='term-header'>Responsibility for Contest Details:</p>

   <p>You are responsible for providing accurate and complete information about the contest, including contest requirements, deadlines, and any other relevant details.</p>

 

   <p className='term-header'>Selection of Winners:</p>

   <p>You agree to select winners of the contest in a fair and impartial manner based on the criteria outlined in the contest details.</p>

 

   <p className='term-header'>Communication with Participants:</p>

   <p>You agree to communicate with participants in a professional and courteous manner, providing updates and feedback as necessary throughout the duration of the contest.</p>

 

   <p className='term-header'>Payment and Rewards:</p>

   <p>You agree to provide any promised rewards or payments to contest winners in a timely manner and in accordance with the terms outlined in the contest details.</p>
 
   <p className='term-header'>Compliance with Laws:</p>

    <p>You agree to comply with all applicable laws and regulations governing contests, including but not limited to laws related to intellectual property rights, consumer protection, and privacy.</p>


   <p className='term-header'>Indemnification:</p>

   <p>You agree to indemnify and hold harmless advidity, its affiliates, and employees from any claims, damages, losses, or liabilities arising out of or related to your creation of contests on advidity.</p>



   <p className='term-header'>Termination of Contest:</p>

   <p>advidity reserves the right to remove or terminate any contest that violates these terms and conditions or advidity's policies.</p>


   <p>By creating a contest on advidity, you acknowledge that you have read, understood, and agree to abide by these terms and conditions.</p>
    </div>
  )
}
