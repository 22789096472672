import React, { useState, useEffect} from 'react'
import './Contest.css'
import video from '../../../Assets/video-placeholder.png'
import ContestFAQ from './ContestFAQ'
import CreateContest from './CreateContest'
import ContestList from './ContestList'
import ViewContest from './ViewContest'
import { useAuth } from '../../../Context/AuthContext'
import axios from 'axios'

export default function Contests() {

  const { currentUser } = useAuth()

  const [tab, setTab] = useState(1)
  const [openContest, setOpenContest] = useState(false)
  const [mycontests, setMyContests] = useState([])
  const [allContests, setAllContests] = useState([])
  const [viewContest, setViewContest] = useState(false)
  const [contestId, setContestId] = useState("")

  useEffect(() => {
    getMyContests()
    getAllContests()
  }, [])

  //Retrieve past and present contests for my company
  const getMyContests = () => {
    axios.post(`${process.env.REACT_APP_API_URL}/contests/get-contests-by-business`, { id: currentUser.uid})
    .then((res) => {
      setMyContests(res.data)
    })
    .catch((e) => {
      console.log(e)
    })
  }
  //Retrive all active contests from all companies
  const getAllContests = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/contests/get-all-active-contests`)
    .then((res) => {
      setAllContests(res.data)
    })
    .catch((e) => {
      console.log(e)
    })
  }

  return (
    <div className='business-outlet'>
      <div id='contest-content'>
          <p className='advidity-header' style={{fontSize: 32}}>Welcome to the <span style={{fontWeight: 600}}>design contest playground</span></p>
          <p className='advidity-subheader' style={{fontSize: 18}}>Find your perfect freelancers through one-off design contests.</p>
          <button className='primary-btn' id='create-job-btn' onClick={() => setOpenContest(true)}>Create new contest</button>

          <div className='tab-container' style={{width: 700, maxWidth: '100%'}}>

            <div className={tab === 1 ? 'tab-active' : 'tab'} onClick={() => setTab(1)}>
              <p>How it works</p>
            </div>
            <div className={tab === 2 ? 'tab-active' : 'tab'} onClick={() => setTab(2)}>
              <p>Your contests</p>
              <div className='tab-square'>
                <p>{mycontests.length}</p>
              </div>
            </div>
            <div className={tab === 3 ? 'tab-active' : 'tab'} onClick={() => setTab(3)}>
              <p>Other client contests</p>
              <div className='tab-square'>
                <p>{allContests.length}</p>
              </div>
            </div>

          </div>

          {tab === 1 && 
          <div style={{maxWidth: 1500}}>

            <div style={{margin: '30px 0', display: 'flex'}}>

              <img style={{width: '45%', marginRight: 20, objectFit: 'cover', objectPosition: 'center'}} src={video} alt='video'/>

              <div id='contest-description'>

                <div style={{ overflow: 'scroll'}}>
                  <p className='advidity-header'>How does the one-off design contest work?</p>
                  <p className='advidity-subheader' style={{lineHeight: 1.5}}>Our contests are designed to elevate your projects and connect you with diverse talent. Here's how it works: Simply submit a contest which will invite you to submit the number of applicants (5-20), the duration of the contest (1-4 weeks) as well as your design needs or project requirements. Our freelancers from around the globe will then bring your vision to life.</p>
                  <p className='advidity-subheader'style={{lineHeight: 1.5}}>As submissions roll in, you'll have the unique opportunity to choose the winning entry that aligns perfectly with your brand. It's a dynamic and efficient way to infuse creativity into your projects while discovering the immense talent within our community.</p>
                </div>
              </div>

            </div>
            
            <div>
              <p className='advidity-header' style={{fontSize: 24, padding: '15px 0', borderTop: '0.4px solid #d4d4d4', borderBottom: '0.4px solid #d4d4d4'}}>Frequently Asked Questions</p>

              <ContestFAQ 
              title={'How are winners selected?'}
              content={"Winners are selected by the business or individual who posted the contest. They choose the entry that best meets their requirements, and the selected freelancer will receive the set fee."}
              />

              <ContestFAQ 
              title={'Is there a limit to the number of contests I can enter?'}
              content={"Feel free to enter as many contsts as you wish, as long as they match your skills and interests. It's worth noting that the more contests you participate in, the more opportunities you have to showcase your talents in your portfolio. Additionally, active participation earns you more points, enhancing your profile and visibility on the platform. So dive into as many contests as you resonate with and let your creativity shine!"}
              />

              <ContestFAQ 
              title={'Can I communicate with the business during the contest?'}
              content={"Some contests may allow communication between freelancers and businesses. Check the specific contest details for communication guidelines."}
              />
            </div>
          </div>
          }

          {tab === 2 && 
            <ContestList contests={mycontests} setViewContest={setViewContest} setContestId={setContestId} />
          }

          {tab === 3 && 
            <ContestList contests={allContests} setViewContest={setViewContest} setContestId={setContestId}/>
          }

      </div>

      {openContest && <CreateContest setOpenContest={setOpenContest} setTab={setTab} getMyContests={getMyContests}/>}
      {viewContest && <ViewContest setViewContest={setViewContest} contestId={contestId}/>}

    </div>
  )
}
