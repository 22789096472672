import React, { useState, useEffect} from 'react'
import moment from 'moment'
import axios from 'axios'

export default function ViewContest({setViewContest, contestId}) {

  const [title, setTitle] = useState("")
  const [budget, setBudget] = useState("")
  const [primarySkill, setPrimarySkill] = useState("")
  const [secondarySkill, setSecondarySkill] = useState("")
  const [description, setDescription] = useState("")
  const [deliverables, setDeliverables] = useState("")
  const [notes, setNotes] = useState("")
  const [submission, setSubmission] = useState("")
  const [deadline, setDeadline] = useState("")

  useEffect(() => {
    if(contestId){
        //Retrieve job info 
        axios.post(`${process.env.REACT_APP_API_URL}/contests/get-contest-by-id`, {contestId})
        .then((res) => {
            console.log(res.data)
            let {title, prizeMoney, primarySkill, secondarySkill, description, deliverables, notes, submissionDate, deadline } = res.data;
            setTitle(title);
            setBudget(prizeMoney)
            setPrimarySkill(primarySkill)
            setSecondarySkill(secondarySkill)
            setDeliverables(deliverables)
            setDescription(description)
            setNotes(notes)
            setSubmission(submissionDate)
            setDeadline(deadline)

        })
        .catch((e) => {
            console.log(e)
        })

    } else {
        return
    }

  }, [contestId])



  return (
    <div id='create-contest'>
        <div id='create-contest-container'>
            <button onClick={() => setViewContest(false)} id='close-contest-btn'>x</button>

            <div className='create-contest-header' style={{display: 'flex', alignItems:'center'}}>
                <div style={{height: 48, width: 48, borderRadius: 8, backgroundColor: '#f1f1f1', marginRight: 20}}>

                </div>
                <div>
                  <p className='advidity-subheader' style={{fontSize: 16, margin: 0}}>{title}</p>
                  <p className='advidity-header' style={{fontSize: 22, }}>{primarySkill} - {secondarySkill}</p>
                </div>
                
             </div>
              <div className='new-contest-container' style={{padding: '3rem'}}>
                <p>Overview</p>

                <p className='advidity-subheader' style={{fontSize: 14, margin: '40px 0 10px 0'}}>Budget</p>
                <p className='advidity-header' style={{fontSize: 20, margin: 0}}>£{budget}</p>

                <p className='advidity-subheader' style={{fontSize: 14, margin: '40px 0 10px 0'}}>Application deadline</p>
                <p className='advidity-header' style={{fontSize: 20, margin: 0}}>{moment(deadline).format(' Do MMMM YYYY')}</p>


                <p className='advidity-subheader' style={{fontSize: 14, margin: '40px 0 10px 0'}}>Submission date</p>
                <p className='advidity-header' style={{fontSize: 20, margin: 0}}>{moment(submission).format(' Do MMMM YYYY')}</p>

                <div style={{borderTop: '1px solid #f1f1f1', borderBottom: '1px solid #f1f1f1', marginTop: 40}}>
                  <p className='advidity-header' style={{fontSize: 20, margin: '40px 0 10px 0'}}>Project task</p>
                  <p className='advidity-subheader' style={{fontSize: 14, margin: '20px 0 40px 0'}}>{description}</p>
                </div>

                <div style={{ borderBottom: '1px solid #f1f1f1', marginTop: 40}}>
                  <p className='advidity-header' style={{fontSize: 20, margin: '40px 0 10px 0'}}>Deliverables/Requirements for submission</p>
                  <p className='advidity-subheader' style={{fontSize: 14, margin: '20px 0 40px 0'}}>{deliverables}</p>
                </div>

                <div style={{ borderBottom: '1px solid #f1f1f1', marginTop: 40}}>
                  <p className='advidity-header' style={{fontSize: 20, margin: '40px 0 10px 0'}}>Additional notes</p>
                  <p className='advidity-subheader' style={{fontSize: 14, margin: '20px 0 40px 0'}}>{!notes ? "No notes added" : notes}</p>
                </div>

              </div>
           
        </div>
    </div>
  )
}
