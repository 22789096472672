import React, { useState, useEffect} from 'react'
import './Jobs.css'
import NotificationAndMessages from './NotificationAndMessages'
import axios from 'axios'
import moment from 'moment'
import icon from '../../Assets/icons/more-info.png'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../Context/AuthContext'
import computer from '../../Assets/course-img.png'

export default function Jobs() {

  let { currentUser } = useAuth()
  const [jobs, setJobs] = useState([])
  const [applied, setApplied] = useState([])
  const [tab, setTab] = useState(1)

  let navigate = useNavigate()

  //Retrive all active jobs from all companies
  const getAllJobs = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/jobs/get-all-active-jobs`)
    .then((res) => {
      setJobs(res.data)
    })
    .catch((e) => {
      console.log(e)
    })
  }

   //Retrive all applied for jobs 
   const getAppliedFor = () => {
    axios.post(`${process.env.REACT_APP_API_URL}/jobs/get-applied-for-by-id`, {applicantId: currentUser.uid})
    .then((res) => {
      setApplied(res.data)
    })
    .catch((e) => {
      console.log(e)
    })
  }

  useEffect(() => {
    getAllJobs()
    getAppliedFor()
  }, [])

  return (
    <div className='business-outlet'>
      <div id='business-dash-content'>
            <p className='advidity-header'>Jobs</p>
            <p className='advidity-subheader'>Find your preferred jobs, contests, and many more</p>

            <div className='create-contest-box'>
              <p style={{fontFamily:'Satoshi', fontSize: 24, letterSpacing: -0.5, color: '#fff'}}>Check out our well tailored jobs in your niches.</p>
              <p style={{fontFamily:'Inter', fontSize: 16, letterSpacing: -0.5, color: '#fff', margin: '1rem 0', maxWidth: 600, lineHeight: 1.6}}>Get paid for jobs and earn points at the same time</p>
              <img style={{height: "90%", position: 'absolute', right: '-1%', bottom: '-5%'}} src={computer} alt='"Courses computer'/>

            </div>

          <div style={{width: '100%', minHeight: 300, display: 'flex', flexWrap: 'wrap'}}>
            <div className='tab-container' style={{width: 400}}>

              <div className={tab === 1 ? 'tab-active' : 'tab'} onClick={() => setTab(1)}>
                <p>All Jobs</p>
                <div className='tab-square'>
                  <p>{jobs.length}</p>
                </div>
              </div>

              <div className={tab === 2 ? 'tab-active' : 'tab'} onClick={() => setTab(2)}>
                <p>Applied</p>
                <div className='tab-square'>
                  <p>{applied.length}</p>
                </div>
              </div>


            </div>
          {tab ===1 &&
            <table className='job-table'>
            <tr>
              <th>Deadline</th>
              <th>Project Title</th>
              <th>Skill Required</th>
              <th>Budget</th>
              <th></th>
            </tr>
          
            {jobs.length < 1 ?
            <tr>
              <td colSpan={5}>No jobs added</td>
            </tr>
            : 
            jobs.map((job, i) => {
              return (
                <tr key={i}>
                  <td>{moment(job.deadline).format('Do MMM YYYY')}</td>
                  <td>{job.title}</td>
                  <td>{job.primarySkill}</td>
                  <td>£{job.budget}</td>
                  <td className='more-info-cell' style={{display: 'flex', alignItems: 'center', flexWrap:'wrap'}} onClick={() => navigate(`/freelance/jobs/${job._id}`)}>
                    <p>More details</p>
                    <img style={{height: 15, width: 15, marginLeft: 10}} src={icon} alt='More info icon'/>
                  </td>


                </tr>
              )
            })
            }
            </table>
          }

          {tab === 2 &&
            <table className='job-table'>
            <tr>
              <th>Project Title</th>
              <th>Stage</th>
              <th>Applied</th>
              <th></th>
            </tr>
          
            {applied.length < 1 ?
            <tr>
              <td colSpan={5}>No jobs applied for</td>
            </tr>
            : 
            applied.map((job, i) => {
              return (
                <tr key={i}>
                  <td>{job.jobTitle}</td>
                  <td>{job.stage}</td>
                  <td>{moment(job.createdAt).format('Do MMM YYYY')}</td>
                  <td className='more-info-cell' style={{display: 'flex', alignItems: 'center', flexWrap:'wrap'}} onClick={() => navigate(`/freelance/jobs/${job.jobId}`)}>
                    <p>More details</p>
                    <img style={{height: 15, width: 15, marginLeft: 10}} src={icon} alt='More info icon'/>
                  </td>


                </tr>
              )
            })
            }
            </table>
          }
          </div>

          
        </div>

        <NotificationAndMessages />
        

      
    </div>
  )
}
