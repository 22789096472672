import React, { useEffect, useRef, useState } from 'react'
import moment from 'moment'
import { useOutletContext, useParams } from 'react-router-dom'
import axios from 'axios'
import { useAuth } from '../../Context/AuthContext'
import { useUser } from '../../Context/UserContext'

export default function Chat() {

  let { id } = useParams()
  let { currentUser } = useAuth()
  let { userInfo } = useUser()

  const [msgs, setMsgs] = useState([])
  const [message, setMessage] = useState("")

  const { getChats } = useOutletContext()

  useEffect(() => {
    getMsgs()
  }, [])

  const getMsgs = () => {
    axios.post(`${process.env.REACT_APP_API_URL}/chats/get-messages-by-chat-id`, {chatId: id})
    .then((res) => {
      setMsgs(res.data)
    })
    .catch((e) => {
      console.log(e)
    })
  }

  const sendMessage = () => {
    let payload ={
      chatId: id,
      message,
      timeStamp: new Date(),
      sentBy: "Business"
    }

    if(!message){
      return
    } else {
      axios.post(`${process.env.REACT_APP_API_URL}/chats/send-business-message`, payload)
      .then((res) => {
        getMsgs(res.data)
        getChats()
        setMessage("")
      })
      .catch((e) => {
        console.log(e)
      })
    }
  }
  const messagesEndRef = useRef(null)

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
  }

  useEffect(() => {
    scrollToBottom()
  }, [msgs]);


  return (
    <div >
      <div style={{overflow:'scroll', height: 'calc(85vh - 100px)'}}>
        {msgs.map((msg, i) => {
          let split = msg.message?.split('\n')

            return (
              <div style={{ width: '100%', minHeight: 100, margin: '1rem 0', display:'flex', alignItems:'center', justifyContent: msg.sentBy === "Freelancer" ? "left" : "right"  }} key={i}>
                {msg.sentBy === "Freelancer" && <div className='msg-avatar-sml'>{msg.name?.split(" ")[0].charAt(0)}{msg.name?.split(" ")[1].charAt(0)}</div>}

                <div  className={msg.sentBy === "Freelancer" ? 'other-sender-msg' : 'my-msg'} >
                  {split.map((line, i) => {
                    return <p key={i} style={{width: '100%',minHeight: 15, border: 'unset', resize: 'none', fontSize: 16, height: 'auto'}}>{line}</p>

                  })}
                  <p style={{color: 'grey', fontSize: '0.8rem', marginTop: 10}}>{moment(msg.timeStamp).format('Do MMM YY, hh:MM a')}</p>
                </div>
              </div>
            )
          })}
          <div ref={messagesEndRef}/>
      </div>
        

    

      <div id='msg-box'>
        {userInfo?.logo ? <img className='msg-avatar-sml' src={userInfo.logo}/>
        :
        <div className='msg-avatar-sml'>
          <p>{currentUser.displayName.charAt(0)}</p>
        </div>
      }
        <textarea  placeholder='Type your message' className='advidity-input' style={{width: '80%', marginRight: 10, resize:'none', paddingTop: 10}} value={message} onChange={(e) => setMessage(e.target.value)}/>

        <button className='btn-small' onClick={sendMessage}>Send</button>
      </div>
      
    </div>
  )
}
