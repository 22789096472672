import { createContext, useContext, useEffect, useState } from 'react'
import { onAuthStateChanged } from "firebase/auth";
import { auth } from '../Firebase';
import axios from 'axios'

const AuthContext = createContext();

export function useAuth() {
    return useContext(AuthContext)
}

export function AuthProvider( {children} ) {

    const [currentUser, setCurrentUser] = useState(null)
    const [admin, setAdmin] = useState(false)
    const [newUser, setNewUser] = useState(false)
    const [role, setRole] = useState("")
    const [isLoading, setIsLoading] = useState(true)
   
    useEffect(() => { 
        let unsubscribe= onAuthStateChanged(auth, (user) => {
            if (user) {
               //Add global header to all http requests to secure endpoints
                axios.defaults.headers.common['AuthToken'] = user.accessToken;

                auth.currentUser.getIdTokenResult()
                .then((idTokenResult) => {
                    // Confirm the user role.
                    if (!!idTokenResult.claims.role) {
                        setRole(idTokenResult.claims.role)
                        setIsLoading(false)
                        setCurrentUser(user)
                        setNewUser(idTokenResult.claims.newUser)
                    } else {
                        setCurrentUser(user)
                        setIsLoading(false)
                        setNewUser(false)

                    }
                })
                .catch((error) => {
                    console.log(error);
                });
            } else {
                setCurrentUser(null)
                setIsLoading(false)
            }
          });

          return unsubscribe;
    }, []);

    const value = {
        currentUser, admin, role, newUser, setNewUser
    }

    return (
        <AuthContext.Provider value={value}>
            {!isLoading && children}
        </AuthContext.Provider>
    )
}
