import React, { useState} from 'react'
import img from '../../Assets/icons/img-placeholder.png'
import { useAuth } from '../../Context/AuthContext'
import { useUser} from '../../Context/UserContext'
import axios from 'axios'
import { useNavigate} from 'react-router-dom'
import more from '../../Assets/more.png'

export default function ApplicantCard({application, getJobApplications}) {

  let { currentUser } = useAuth()
  let { userInfo } = useUser()

  const [popupOpen, setPopupOpen] = useState(false)

  let navigate = useNavigate()

  const sendMsg = () => {
    let payload = {
      businessId: currentUser.uid,
      businessName: currentUser.displayName,
      businessEmail: currentUser.email,
      businessRead: true,
      businessImg: userInfo.logo,
      freelancerName: application.name,
      freelancerEmail: application.email,
      freelancerId: application.applicantId,
      freelancerImg: application.applicantImg,
      freelancerRead: false,
      lastMsgAdded: new Date(),
    }

    axios.post(`${process.env.REACT_APP_API_URL}/chats/open-chat`, payload)
    .then((res) => {
      console.log(res.data)
      navigate(`/business/messages/${res.data._id}`)
    })
    .catch((e) => {
      console.log(e)
    })
  }

  const setStage = (stage) => {

    console.log(stage)
    axios.post(`${process.env.REACT_APP_API_URL}/jobs/set-job-stage`, {id: application._id, stage})
    .then((res) => {
      getJobApplications()
    })
    .catch((e) => {
      console.log(e)
    })
  }

  const handleOffer = () => {
    if(window.confirm("Are you sure you want to make this job offer?")){

      //Add function to disable anu future jb applications and set job status as pending

      //Build out logic to send notifications and emails to freelancer, most likely om back end

      //Set application status to offered
      axios.post(`${process.env.REACT_APP_API_URL}/jobs/set-job-stage`, {id: application._id, stage: "Offered"})
      .then((res) => {
        getJobApplications()
      })
      .catch((e) => {
        console.log(e)
      })
    } else {
      return
    }
  }

  return (
    <div className='applicant-card'>

        <div style={{display:'flex', alignItems: 'center',justifyContent:'space-between', borderBottom: '1px solid #f1f1f1', paddingBottom: 10}}>
            <img className='pointer' style={{marginRight: 10, height: 70, width: 70}} src={img} alt='Freelancer- icon'/>
            <div style={{width: 'calc(100% - 100px)'}}>
                <p className='advidity-header' style={{fontSize: 18}}>{application.name}</p>
                <p className='advidity-subheader' style={{fontSize: 16, margin: '5px 0'}}>{application.jobTitle}</p>
            </div>
            <div style={{position:'relative'}} onClick={() => setPopupOpen(!popupOpen)}>            
              <img style={{marginRight: 10, height: 30, width: 30}} src={more} alt='More- icon'/>
             {popupOpen && 
              <div style={{minWidth: 180, minHeight: 170, backgroundColor:'#f1f1f1', position:'absolute', right: 50, top: 0, borderRadius: 8, padding: 10, boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px'}}>
                <p style={{color: 'grey', marginBottom: 10}}>Move to</p>
                <div className='pointer' onClick={() => setStage('Received')} style={{backgroundColor: '#fafafa', padding: 5}}>
                  <p>Application Received</p>
                </div>
                <div className='pointer' onClick={() => setStage('Screening')} style={{backgroundColor: '#f1f1f1', padding: 5}}>
                  <p>Screening Stage</p>
                </div>
                <div className='pointer' onClick={() => setStage('First Interview')} style={{backgroundColor: '#fafafa', padding: 5}}>
                  <p>First Interview</p>
                </div>
                <div className='pointer' onClick={() => setStage('Second Interview')} style={{backgroundColor: '#f1f1f1', padding: 5}}>
                  <p>Second Interview</p>
                </div>
               
              </div>
              }
            </div>
              

        </div>
        <div className='advidity-row' style={{justifyContent:'space-between', flexWrap:'wrap'}}>
            <a className='job-chip' href={application.link} target='_blank'>{application.link}</a>
            <div className='advidity-row' style={{justifyContent:'space-between', flexWrap:'wrap', width: '100%'}}>
              <button onClick={sendMsg} className='job-chip' style={{width: '45%', border: 'unset', flexShrink: 0, backgroundColor: '#EB9486', color:'white'}}>Send Message</button>
              <button onClick={handleOffer} className='job-chip' style={{width: '45%', border: 'unset', flexShrink: 0, backgroundColor: '#272838', color:'#EB9486'}}>Offer Job</button>
            </div>
            

        </div>

        <p style={{margin: '10px 0'}}>{application.coverLetter}</p>
        
    </div>
  )
}
