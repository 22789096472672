import React, { useState} from 'react'
import NotificationAndMessages from './NotificationAndMessages'
import wallet from '../../Assets/empty-wallet.png'
import axios from 'axios'
import { toast } from 'react-toastify'
import { useAuth } from '../../Context/AuthContext'
import { useUser } from '../../Context/UserContext'
import success from '../../Assets/success.png'

export default function Wallet() {

  const [ signUpInProgress, setSignupInProgress] = useState(false)

  const { currentUser } = useAuth()
  const { getUserInfo, userInfo } = useUser()

  const createConnectAccount = () => {
    setSignupInProgress(true)
    axios.post(`${process.env.REACT_APP_API_URL}/payments/create-connect-account`, {
        userId: currentUser.uid,
        email: currentUser.email,
    })
    .then((res) => {
        console.log(res.data)
        window.open(res.data.url);
        setSignupInProgress(false)
        getUserInfo()

    })
    .catch((error) => {
        toast.error('A problem occurred with the payment system')
        setSignupInProgress(false)
        console.log(error)
    })
}

  return (
      <div className='profile-container'>
      {userInfo?.connectData.paymentsPermitted

        ?
        <div style={{width: '100%',minHeight: '100vh', display: 'flex', justifyContent: 'baseline', alignItems:'center', flexDirection:'column', paddingTop: 50}}>
            <p className='advidity-header'>Congratulations! You're all set up to receive payments</p>

            <img style={{width: '50%', maxWidth: 700, minWidth: 200, marginTop: 50}} src={success}/>
        </div>

        :
        <>
            <p className='advidity-header'>Wallet</p>
            <p className='advidity-subheader'>Manage all you money in and out</p>
          
         
           <div className='dash-contests'>
              <p style={{fontFamily:'Satoshi', fontSize: 24, letterSpacing: -0.5, color: '#272838'}}>Set up your wallet to start getting paid for every job you complete.</p>
              <p style={{fontFamily:'Inter', fontSize: 16, letterSpacing: -0.5, color: 'grey', margin: '1rem 0', maxWidth: 600, lineHeight: 1.6}}>Check pending payments, payment history and more</p>
              
            </div>

            <div style={{width: '100%', minHeight: 400, display: 'flex', flexWrap: 'wrap', flexDirection: 'column', justifyContent: 'center', alignItems:'center'}}>
              
              <img style={{width: '30%', margin: '2rem'}}  src={wallet}/>
              <p className='advidity-subheader'>No wallet has been set up yet</p>

              {userInfo?.connectData === null && 
                <button className='primary-btn' onClick={createConnectAccount} disabled={signUpInProgress}>
                  {signUpInProgress ? "Setting up..." : "Set up with Stripe"}
                  </button>
              }
              {
              (userInfo?.connectData.accountId && !userInfo.connectData.paymentsPermitted) 
              && 
                <button className='primary-btn' onClick={createConnectAccount} disabled={signUpInProgress}>
                  {signUpInProgress ? "Setting up..." : "Continue Stripe Setup"}
                </button>
              }

            </div>
          </>
          }
           
        </div>

        

  )
}
