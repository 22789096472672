import React, {useState, useEffect} from 'react'
import axios from 'axios'
import { useAuth } from '../../Context/AuthContext'
import ApplicantCard from './ApplicantCard'

export default function Applicants() {

  let { currentUser } = useAuth()

  const [tab, setTab] = useState(1)
  const [applications, setApplications] = useState([])
  const [jobs, setJobs] = useState([])
  const [selectedJob, setSelectedJob] = useState("")
  const [received, setReceived] = useState([])
  const [screening, setScreening] = useState([])
  const [first, setFirst] = useState([])
  const [second, setSecond] = useState([])
  const [offered, setOffered] = useState([])


  useEffect(() => {
    if(selectedJob){
      let received = applications.filter((application) => (application.jobId === selectedJob) && (application.stage === 'Received'))
      let screening = applications.filter((application) => (application.jobId === selectedJob) && (application.stage === 'Screening'))
      let first = applications.filter((application) => (application.jobId === selectedJob) && (application.stage === 'First Interview'))
      let second = applications.filter((application) => (application.jobId === selectedJob) && (application.stage === 'Second Interview'))
      let offered = applications.filter((application) => (application.jobId === selectedJob) && (application.stage === 'Offered'))

      setReceived(received)
      setScreening(screening)
      setFirst(first)
      setSecond(second)
      setOffered(offered)
    } else {
      let received = applications.filter((application) => (application.stage === 'Received'))
      let screening = applications.filter((application) => (application.stage === 'Screening'))
      let first = applications.filter((application) => (application.stage === 'First Interview'))
      let second = applications.filter((application) => (application.stage === 'Second Interview'))
      let offered = applications.filter((application) => (application.stage === 'Offered'))

      setReceived(received)
      setScreening(screening)
      setFirst(first)
      setSecond(second)
      setOffered(offered)

    }
  }, [selectedJob])


  const getJobApplications = () => {
    axios.post(`${process.env.REACT_APP_API_URL}/jobs/get-applications-by-business`, { businessId: currentUser.uid })
    .then((res) => {
      setApplications(res.data)
      if(selectedJob){
        let received = res.data.filter((application) => (application.jobId === selectedJob) && (application.stage === 'Received'))
        let screening = res.data.filter((application) => (application.jobId === selectedJob) && (application.stage === 'Screening'))
        let first = res.data.filter((application) => (application.jobId === selectedJob) && (application.stage === 'First Interview'))
        let second = res.data.filter((application) => (application.jobId === selectedJob) && (application.stage === 'Second Interview'))
        let offered = res.data.filter((application) => (application.jobId === selectedJob) && (application.stage === 'Offered'))

        setReceived(received)
        setScreening(screening)
        setFirst(first)
        setSecond(second)
        setOffered(offered)

      } else {
        let received = res.data.filter((application) => (application.stage === 'Received'))
        let screening = res.data.filter((application) => (application.stage === 'Screening'))
        let first = res.data.filter((application) => (application.stage === 'First Interview'))
        let second = res.data.filter((application) => (application.stage === 'Second Interview'))
        let offered = res.data.filter((application) => (application.stage === 'Offered'))

        setReceived(received)
        setScreening(screening)
        setFirst(first)
        setSecond(second)
        setOffered(offered)

      }
    })
    .catch((e) => {
      console.log(e)
    })
  }

  const getActiveJobs = () => {
    axios.post(`${process.env.REACT_APP_API_URL}/jobs/get-active-jobs-by-business`, { id: currentUser.uid })
    .then((res) => {
      console.log(res.data)
      setJobs(res.data)
    })
    .catch((e) => {
      console.log(e)
    })
  }

  useEffect(() => {
    getJobApplications()
    getActiveJobs()
  }, [])

  return (
    <div className='business-outlet'>
       <div style={{padding: '2rem'}}>
        <p className='advidity-header'>Applicants</p>
        <div className='advidity-row' style={{justifyContent:'space-between'}}>
          <p className='advidity-subheader'>Track list of talents that have applied to your job posts</p>
          <select className='advidity-input' style={{width: 300, fontSize: 16}} value={selectedJob} onChange={(e) => setSelectedJob(e.target.value)}>
            <option value={""}>Select Job to track</option>
            {jobs.map((job, i) => {
              return(
                <option value={job._id} key={i}>{job.title}</option>
              )
            })
            }
          </select>
        </div>

        <div style={{width: '100%', minHeight: 300, display: 'flex', flexWrap: 'wrap'}}>
            <div className='tab-container' style={{width: '100%', overflow: 'scroll'}}>
                <div className={tab === 1 ? 'tab-active' : 'tab'} onClick={() => setTab(1)}>
                  <p>Applications Received</p>
                  <div className='tab-square'>
                    <p>{received.length}</p>
                  </div>
                </div>

                <div className={tab === 2 ? 'tab-active' : 'tab'} onClick={() => setTab(2)}>
                  <p>Screening Test</p>
                  <div className='tab-square'>
                    <p>{screening.length}</p>
                  </div>
                </div>

                <div className={tab === 3 ? 'tab-active' : 'tab'} onClick={() => setTab(3)}>
                  <p>First-stage Interview</p>
                  <div className='tab-square'>
                    <p>{first.length}</p>
                  </div>
                </div>

                <div className={tab === 4 ? 'tab-active' : 'tab'} onClick={() => setTab(4)}>
                  <p>Second-stage Interview</p>
                  <div className='tab-square'>
                    <p>{second.length}</p>
                  </div>
                </div>

                <div className={tab ===5 ? 'tab-active' : 'tab'} onClick={() => setTab(5)}>
                  <p>Offered</p>
                  <div className='tab-square'>
                    <p>{offered.length}</p>
                  </div>
                </div>
            </div>

            {tab === 1 &&
              received.map((application, i) => {
                return <ApplicantCard application={application} getJobApplications={getJobApplications} key={i}/>
              })
            }
             {tab === 2 &&
              screening.map((application, i) => {
                return <ApplicantCard application={application} getJobApplications={getJobApplications} key={i}/>
              })
            }
            {tab === 3 &&
              first.map((application, i) => {
                return <ApplicantCard application={application} getJobApplications={getJobApplications} key={i}/>
              })
            }
            {tab === 4 &&
              second.map((application, i) => {
                return <ApplicantCard application={application} getJobApplications={getJobApplications} key={i}/>
              })
            }
            
            {tab === 5 &&
              offered.map((application, i) => {
                return <ApplicantCard application={application} getJobApplications={getJobApplications} key={i}/>
              })
            }
        </div>

      </div>
    </div>
  )
}
