import React, { useState } from 'react';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import axios from 'axios';

const PaymentForm = ({ userId, name, email, amount, connectedAccountId }) => {
  const [error, setError] = useState(null);
  const [paymentSuccess, setPaymentSuccess] = useState(false);

  let stripe = useStripe()
  let elements = useElements()

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError(null)
    
    console.log(stripe, elements)

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement('card');

    try {
      const { error } = await stripe.createToken(cardElement);

      if (error) {
        console.error(error);
        setError(error.message || 'An error occurred.');
      } else {

        const response = await axios.post(`${process.env.REACT_APP_API_URL}/payments/create-payment-intent`, {
            userId,
            name,
            email,
            amount,
            connectedAccountId,
        });

        // Assuming your server returns a clientSecret
        const { clientSecret } = response.data;

        const result = await stripe.confirmCardPayment(clientSecret, {
          payment_method: {
            card: cardElement,
          },
        });

        if (result.error) {
          console.error(result.error);
          setError(result.error.message || 'Payment failed.');
        } else if (result.paymentIntent.status === 'succeeded') {
          // Payment was successful
          setPaymentSuccess(true);
        }
      }
    } catch (error) {
      console.error('Error:', error.message);
      setError('An error occurred.');
    }
  };

  return (
      <form onSubmit={(e) => handleSubmit(e)}>
        <label>
          Card details
          <CardElement />
        </label>
        {error && <p style={{ color: 'crimson' }}>{error}</p>}
        {paymentSuccess && <p style={{ color: 'green' }}>Payment successful!</p>}

        <button type='submit'>Pay</button>
      </form>
  );
};

export default PaymentForm;