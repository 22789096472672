import React from 'react'
import './Terms.css'

export default function FreelancerTerms() {
  return (
    <div className='terms'>
        <h1 style={{fontFamily: 'Satoshi'}}>Advidity Contest Application Terms and Conditions</h1>

 

    <p>By submitting an application to participate in a contest on advidity, you agree to the following terms and conditions:</p>

 

    <p className='term-header'>Application Guidelines:</p>

   <p>- You agree to submit applications that are truthful, accurate, and in compliance with the specific requirements outlined for the contest.</p>

 

   <p className='term-header'>Ownership of Submitted Materials:</p>

   <p>- You retain ownership of any materials you submit as part of your contest application. However, by submitting materials, you grant advidity a non-exclusive, worldwide, royalty-free licence to use, reproduce, and display the materials for promotional purposes related to the contest and advidity's services.</p>

 

   <p className='term-header'>Eligibility:</p>

   <p>- You confirm that you meet the eligibility criteria specified for participation in the contest. You must not submit an application on behalf of another individual or entity without proper authorisation.</p>

 

   <p className='term-header'>Accuracy of Information:</p>

   <p>- You are responsible for ensuring that all information provided in your contest application is accurate, complete, and up-to-date.</p>

 

   <p className='term-header'>Selection Process:</p>

   <p>- You understand that participation in the contest does not guarantee selection or winning. Contest winners will be selected based on the criteria outlined in the contest details.</p>

 

   <p className='term-header'>Communication with advidity:</p>

   <p>- You agree to communicate with advidity and contest organisers in a professional and respectful manner throughout the application process.</p>

 

   <p className='term-header'>Compliance with Laws:</p>

   <p>- You agree to comply with all applicable laws and regulations governing contest participation, including but not limited to laws related to intellectual property rights, data protection, and privacy.
</p>
 

   <p className='term-header'>Acceptance of Results:</p>

   <p>- You agree to accept the results of the contest selection process as final and binding.</p>

 

   <p className='term-header'>Indemnification:</p>

   <p>- You agree to indemnify and hold harmless advidity, its affiliates, and employees from any claims, damages, losses, or liabilities arising out of or related to your contest application or participation.</p>

 

   <p className='term-header'>Termination of Application:</p>

   <p>- advidity reserves the right to reject or remove any contest application that violates these terms and conditions or advidity's policies.</p>

 

   <p>By submitting an application to participate in a contest on advidity, you acknowledge that you have read, understood, and agree to abide by these terms and conditions.</p>
    </div>
  )
}
