import React, { useState, useEffect } from 'react'
import Modal from 'react-modal';
import { useAuth } from '../../../Context/AuthContext'
import axios from 'axios'
import { toast } from 'react-toastify'
//firebase
import { storage } from '../../../Firebase'
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

export default function PortfolioModal({ porfolioModalOpen, setPorfolioModalOpen, portfolio, setPortfolio, getPortfolios}) {

    let { currentUser } = useAuth()

    //modal state
    const [title, setTitle] = useState()
    const [description, setDescription] = useState()
    const [link, setLink] = useState()
    const [portfolioImg, setPortfolioImg] = useState()

    useEffect(() => {

        if( portfolio ){
            setTitle(portfolio.title)
            setDescription(portfolio.description)
            setLink(portfolio.link)
            setPortfolioImg(portfolio.img)
        }

    }, [portfolio])

    
    function closeModal() {
        setPorfolioModalOpen(false);
        setPortfolio(null)
    }

    const handleDelete = () => {
        if(window.confirm("Are you sure you want to delete?")){
            axios.post(`${process.env.REACT_APP_API_URL}/portfolio/delete-item`, {_id: portfolio._id})
            .then((res) => {
              getPortfolios()
              closeModal()
              deductPoints(20)
            })
            .catch((e) => {
              console.log(e)
            })
        } else {
            return
        }
       
    }

    const deductPoints = (points) => {
      axios.post(`${process.env.REACT_APP_API_URL}/freelance/deduct-points`, {_id: currentUser.uid, points})
            .then((res) => {
              getPortfolios()
              closeModal()
            })
            .catch((e) => {
              console.log(e)
            })
    }

    const handleUpdate = () => {

            let payload = {title, description, link, img: portfolioImg}

            if(!title || !description){
              toast.error("Please add title and description")
            } else {
       
              axios.post(`${process.env.REACT_APP_API_URL}/portfolio/update-item`, {_id: portfolio._id, payload })
              .then((res) => {
                getPortfolios()
                closeModal()
              })
              .catch((e) => {
                console.log(e)
              })
          }
      
       
    }

    const updateImg = (file) =>{


      const imgRef = ref(storage, `freelancers/portfolio/${currentUser.uid}/${portfolio._id}`);

      uploadBytes(imgRef, file)
      .then(() => {
 
          getDownloadURL(imgRef)
          .then((url) => {
            setPortfolioImg(url)
          })
          .catch((error) => {
            console.log(error)
            toast.error("Error")
          });
        })
        .catch((error) => {
            console.log(error)
        });
     }
  
  return (
    <Modal
    isOpen={porfolioModalOpen}
    onRequestClose={closeModal}
    style={customStyles}
    contentLabel="Example Modal"
  >
     { portfolio
     ?
     <>
    <h2 className='advidity-header'>Edit portfolio item</h2>

    <input className='advidity-input' placeholder='Portfolio title/name' value={title} onChange={(e) => setTitle(e.target.value)}/>
    <textarea className='advidity-input' placeholder='Brief description of work' style={{resize:'none'}} value={description} onChange={(e) => setDescription(e.target.value)}/>

    <input className='advidity-input' placeholder='Link (optional)' value={link} onChange={(e) => setLink(e.target.value)}/>

    <label className="advidity-subheader">Upload image (optional)</label>

    <div className='add-container' style={{margin:'0 auto', position:'relative'}}>
          <img style={{width: '100%', height: '100%', borderRadius: 30, padding: 10}} src={portfolioImg}/>
          <input type="file" accept="image/*" id='img-input'  onChange={(e)=>updateImg(e.target.files[0])}/>

    </div>

    <div style={{display:'flex', justifyContent:'space-evenly'}}>
        <button className='primary-btn' style={{backgroundColor: 'green', color:'white'}} onClick={handleUpdate}>update</button>
        <button className='primary-btn' style={{backgroundColor: 'crimson', color:'white'}} onClick={handleDelete}>delete</button>
        <button className='primary-btn' style={{backgroundColor: '#dfdfdf'}} onClick={closeModal}>close</button>
    </div>

    </>
    :
    null}
    
    
  </Modal>

  )
}
const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      display:'flex',
      flexDirection:'column',
      backgroundColor: '#fefefe',
      borderRadius: '30px',
      boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
      textAlign:'center'

    },
  };
  