import React, { useState} from 'react'
import { Link } from 'react-router-dom'
export default function NotificationAndMessages() {

  const [notification, setNotifications] = useState([])
  const [msgs, setMsgs] = useState([])


  return (
    <div id='business-dash-sidebar'>
        <div id='notifications-container'>
          <p style={{fontFamily: 'Satoshi', fontSize: 20, letterSpacing: -0.5}}>Notifications</p>
          <p style={{fontFamily: 'Inter', fontSize: 14, letterSpacing: -0.5, color:'grey', marginTop: 5}}>Stay up to date with the latest news</p>
          <div id='notifications-scroller'>

          {msgs.length < 1 ?
              <p className='advidity-subheader' style={{fontSize: 16, width: 287}}>No notifications</p>
              :
              msgs.map((msg, i) => {
                return (
                  <div className='notification-preview' key={i}>
                  </div>
                )
              })
            }
        
          </div>

          <Link>View all</Link>
        </div>

        <div id='bus-message-container'>
          <p style={{fontFamily: 'Satoshi', fontSize: 20, letterSpacing: -0.5}}>Messages</p>
          {/*<p style={{fontFamily: 'Inter', fontSize: 14, letterSpacing: -0.5, color:'grey', marginTop: 5}}>You have unread messages</p>*/}

          <div id='message-scroller'>
            {msgs.length < 1 ?
              <p className='advidity-subheader' style={{fontSize: 16}}>Inbox Empty</p>
              :
              msgs.map((msg, i) => {
                return (
                  <div className='msg-preview' key={i}>

                  </div>
                )
              })
            }
             
          </div>

          <Link to={'/freelance/messages'} >
            <button id='go-to-msgs'>
              Go to messages
            </button>
          </Link>
        </div>
      </div>
    
  )
}
