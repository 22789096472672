import React, { useState, useEffect} from 'react'
import { Link, Outlet, useLocation } from 'react-router-dom'
import './FreelanceUI.css'
import {  signOut, sendEmailVerification } from "firebase/auth";
import { auth } from '../../Firebase';
import { useAuth } from '../../Context/AuthContext'
import { useUser } from '../../Context/UserContext'
import { toast } from 'react-toastify'
import Welcome from '../Welcome';
import logo from '../../Assets/logo.png'
import axios from 'axios'

export default function FreelanceUI() {

  let { currentUser, newUser, setNewUser } = useAuth()
  let location = useLocation();

  let { userInfo } = useUser()
  const [active, setActive] = useState('')


  useEffect(() => {
    let url = location.pathname

    if(url === '/freelance'){
      setActive("Dashboard")
    } else if(url.startsWith('/freelance/jobs')){
      setActive("Jobs")
    } else if(url.startsWith('/freelance/learning-portal')){
      setActive("Courses")
    } else if(url.startsWith('/freelance/contests')){
      setActive("Contests")
    } else if(url.startsWith('/freelance/points')){
      setActive("Points")
    } else if(url.startsWith('/freelance/portfolio')){
      setActive("Portfolio")
    } else if(url.startsWith('/freelance/messages')){
      setActive("Messages")
    } else {
      setActive("")
    }

  }, [location])


    const handleLogout = () =>{
        signOut(auth).then(() => {
          // Sign-out successful.
        }).catch((error) => {
          // An error happened.
        });
      }

  const sendVerification = () => {
    sendEmailVerification(auth.currentUser)
    .then(() => {
      toast.success("Email verification sent!")
    });
  }



  return (
    <div style={{width: '100vw', height: '100vh', display:'flex'}}>
            <header id='business-nav'>
          <div>
            <img src={logo} style={{width: 150, marginBottom: 50}}/>

            <nav>
              <p style={{fontFamily: 'Inter', fontSize: 11, color:'#111111', opacity: 0.5}}>MENU</p>
                <Link to={'/business'} className={active === 'Dashboard' ? 'business-nav-item-active' : 'business-nav-item'} >
                  <img className={active === 'Dashboard' ? 'nav-icon icon-filter' : 'nav-icon'} src={require('../../Assets/icons/home.png')} alt='Home icon'/>
                  <p className={active === 'Dashboard' ? 'business-link-txt-active' : 'business-link-txt'}>Dashboard</p>
                </Link>
                <Link to={'jobs'} className={active === 'Jobs' ? 'business-nav-item-active' : 'business-nav-item'} >
                  <img className={active === 'Jobs' ? 'nav-icon icon-filter' : 'nav-icon'} src={require('../../Assets/icons/jobs-freelancer.png')} alt='Jobs icon'/>
                  <p className={active === 'Jobs' ? 'business-link-txt-active' : 'business-link-txt'}>Jobs</p>
                </Link>
                <Link to={'learning-portal'} className={active === 'Courses' ? 'business-nav-item-active' : 'business-nav-item'} >
                  <img className={active === 'Courses' ? 'nav-icon icon-filter' : 'nav-icon'} src={require('../../Assets/icons/courses.png')} alt='Courses icon'/>
                  <p className={active === 'Courses' ? 'business-link-txt-active' : 'business-link-txt'}>Courses</p>
                </Link>
                <Link to={'contests'} className={active === 'Contests' ? 'business-nav-item-active' : 'business-nav-item'} >
                  <img className={active === 'Contests' ? 'nav-icon icon-filter' : 'nav-icon'} src={require('../../Assets/icons/contests.png')} alt='Contests icon'/>
                  <p className={active === 'Contests' ? 'business-link-txt-active' : 'business-link-txt'}>One-off Contests</p>
                </Link>
                {/*<Link to={'applications'} className={active === 'Applications' ? 'business-nav-item-active' : 'business-nav-item'} >
                  <img className={active === 'Applications' ? 'nav-icon icon-filter' : 'nav-icon'} src={require('../../Assets/icons/applications.png')} alt='Applicants icon'/>
                  <p className={active === 'Applications' ? 'business-link-txt-active' : 'business-link-txt'}>Applications</p>
  </Link>*/}
                <Link to={'points'} className={active === 'Points' ? 'business-nav-item-active' : 'business-nav-item'} >
                  <img className={active === 'Points' ? 'nav-icon icon-filter' : 'nav-icon'} src={require('../../Assets/icons/points.png')} alt='Points icon'/>
                  <p className={active === 'Points' ? 'business-link-txt-active' : 'business-link-txt'}>Points</p>
                </Link>
                <Link to={'portfolio'} className={active === 'Portfolio' ? 'business-nav-item-active' : 'business-nav-item'} >
                  <img className={active === 'Portfolio' ? 'nav-icon icon-filter' : 'nav-icon'} src={require('../../Assets/icons/portfolio.png')} alt='Portfolio icon'/>
                  <p className={active === 'Portfolio' ? 'business-link-txt-active' : 'business-link-txt'}>Portfolio</p>
                </Link>
                <Link  to={'messages'} className={active === 'Messages' ? 'business-nav-item-active' : 'business-nav-item'} >
                  <img className={active === 'Messages' ? 'nav-icon icon-filter' : 'nav-icon'} src={require('../../Assets/icons/messages.png')} alt='Messages icon'/>
                  <p className={active === 'Messages' ? 'business-link-txt-active' : 'business-link-txt'}>Messages</p>
                </Link>

                <button className='business-nav-item' id='business-logout-btn' onClick={handleLogout}>
                  <img className='nav-icon' src={require('../../Assets/icons/logout.png')} alt='Logout icon'/>
                  <p className='business-link-txt'>Logout</p>
                </button>

            </nav>
            </div>

            <Link to={'settings'}>
              <div className='advidity-row' >
                {userInfo?.img ? <img id='business-avatar'  src={userInfo?.img} alt={currentUser?.displayName}/>
                :
                <div id='business-avatar' style={{display:'flex', justifyContent:'center', alignItems: 'center', flexWrap:'wrap', flexShrink: 0}}>
                  <p style={{fontFamily: 'Inter', fontSize: 12, textAlign:'center'}}>Upload Profile</p>
                </div>
                }

                <p style={{marginLeft: 15, fontFamily: 'Inter', fontWeight: 600}}>{currentUser?.displayName}</p>
              </div>
            </Link>
        </header>


        {/*newUser ?
          <Welcome setNewUser={setNewUser}/>
  : */}
          {!currentUser.emailVerified && 
        <div id='blur-overlay'>
          <div className='blur-info-container'>

            <div style={{width: '100%', height: 300, backgroundColor: '#f1f1f1', borderRadius: 12, marginBottom: 20}}>

            </div>

            <h2 className='advidity-header' style={{fontSize: 32}}>We just sent you a verification email</h2>

            <p className='advidity-subheader' style={{fontSize: 18}}>Hi there, we just sent you a secure verification link. Kindly check your inbox and spam. </p>

            <button className='primary-btn' style={{fontSize: 18, width: 150}} onClick={sendVerification}>Resend link</button>

          </div>

        </div>
          }

     
        <Outlet context={{active, setActive}}/>

        
    </div>
  )
}
